import React from 'react'
import PluginCheckBox from './PluginCheckBox';

const SideBarPlugins = ({ plugins, clickedPlugin, handlePluginCheckBoxChange, handleToggleDescription }) => {

    return (
        <div className="sidebar-plugins">
            <div className="sidebar-plugins-title">
                Plugins
            </div>
            <div className="sidebar-plugins">
                {plugins.map((plugin, index) => (
                    <PluginCheckBox
                        key={plugin.name}
                        id={`plugin-${index}`}
                        label={plugin.name}
                        icon={plugin.image}
                        isChecked={plugin.checked}
                        handlePluginCheckBoxChange={() => handlePluginCheckBoxChange(index)}
                        handleToggleDescription={() => handleToggleDescription(index)}
                        description={plugin.desc}
                        isClicked={clickedPlugin === index}
                    />
                ))}
            </div>
        </div>
    )
}

export default SideBarPlugins