import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 

const ResetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
  const handleResetPassword = async (e) => {
    console.log('Reset Password button pressed');
      e.preventDefault();
      if (email === '' || password === '') {
        alert('Please enter all the fields!');
        return;
      }
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);

      const response = await fetch('https://production.terabh.com/auth/reset_password', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data);
      navigate('/login'); // Redirect to the login page upon successful reset password
    } catch (error) {
      setError('Resetting password failed!');
    }
  };
  return (
      <div className='reset-password-container'>
      <div className='reset-password-header'>
          <div className='title'>
            <img className='title-image' src={require('../images/logo.png')} alt="Logo" />
            <div className='title-text'>Terabh<span>AI</span></div>
        </div>
        <div className='reset-password-header-buttons'>
          <button className='reset-password-header-login' onClick={() => navigate('/login')}>Login</button>
        <button className='login-header-signup' onClick={() => navigate('/signup')}>Signup</button>
        </div>
          </div>
          <div className='reset-password-body'>
        <div className='welcome-text'>
          Welcome to Terabh<span>AI</span>
        </div>
        <div className='reset-password-text'>Reset your password!</div>
              <div className='reset-password-form'>
                  <form onSubmit={handleResetPassword}>
                      <div className='form-group'>
                          <label htmlFor="email">Email</label>
                          <input
                              type="text"
                              id="email"
                              value={email}
                              placeholder='Enter your email'
                              onChange={(e) => setEmail(e.target.value)}
                          />
                      </div>
                      <div className='form-group'>
                          <label htmlFor="password">Password</label>
                          <input
                              type="password"
                              id="password"
                              value={password}
                              placeholder='Enter your password'
                              onChange={(e) => setPassword(e.target.value)}
                          />
                      <div className='reset-password-button'>
                              <button type="submit">Reset Password</button>
                    </div>
            </div>
            {error && <div className="error-message">{error}</div>}
                      <div className="signup-instead">
                          Don't have an account? <a href='/signup'>Sign up</a>
                      </div>
                      <div className="remember-password">
                          Remember Password? <button onClick={() => navigate('/login')}>Login</button>
                      </div>
                  </form>
              </div>
              <div className="terabh-text">
                  <img src={require('../images/terabh-text.png')} alt="terabh-text" />
              </div>
          </div>
    </div>
  )
}

export default ResetPassword