import React,{useEffect,useState} from 'react';
import { getProfile } from '../service/api';

const UserList = ({ usernames, onUsernameClick,loading }) => {
  console.log(usernames);
  const [user,setUser] = useState('');

  const getUser = async ()=>{
    const response = await getProfile(localStorage.getItem('access_token'));
    setUser(response);
  }

  useEffect(()=>{
    getUser();
    if(usernames.length>0){
    }
  },[])

  function formatTimestamp(timestampStr) {
    const timestamp = new Date(timestampStr);
    const currentDate = new Date();
    const yesterday = new Date(currentDate.getTime() - (24 * 60 * 60 * 1000));
  
    if (
      timestamp.getFullYear() === currentDate.getFullYear() &&
      timestamp.getMonth() === currentDate.getMonth() &&
      timestamp.getDate() === currentDate.getDate()
    ) {
      return timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (
      timestamp.getFullYear() === yesterday.getFullYear() &&
      timestamp.getMonth() === yesterday.getMonth() &&
      timestamp.getDate() === yesterday.getDate()
    ) {
      return 'Yesterday';
    } else {
      return timestamp.toLocaleDateString();
    }
  }

  return (
    <>
    {loading && <div className='text-secondary1 font-helvetica'>Loading...</div>}
    <ul className="mt-4 space-y-2">
      {usernames.map((username) => (
        <>
        <li
          key={username.username}
          onClick={() => onUsernameClick(username.username)}
          className="flex items-center justify-between p-2 cursor-pointer rounded-lg transition-colors font-helvetica"
        >
          
          <div className='flex flex-row'>
          <div className="w-14 h-14 bg-secondary1 text-black text-3xl font-bold font-helvetica rounded-full flex items-center justify-center mr-3">
            {username.username[0].toUpperCase()}
          </div>
          <div>
          <span className="text-white text-xl font-helvetica">{(username.username===user)? 'My Bot':username.username}</span>
          <p className='font-helvetica'>{username.content}</p>
          </div>
          </div>
          <div className='text-text2 font-helvetica'>
              {formatTimestamp(username.timestamp)}
          </div>
        </li>
      <hr className='border-[#252525]'/>
      </>
      ))}
    </ul>
    </>
  );
};

export default UserList;
