import React, { useState, useRef } from 'react';

const MicrophoneRecorder = ({ onClose, selectedFile, setSelectedFile }) => {
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);

    const toggleRecording = async () => {
        if (isRecording) {
            setIsRecording(false);
            mediaRecorder.current.stop();
        }
        else if (onClose) {
            setIsRecording(false);
            mediaRecorder.current.stop();
            onClose();
        }
        else {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorder.current = new MediaRecorder(stream, { mimeType: 'audio/webm' });
            mediaRecorder.current.ondataavailable = (event) => {
                audioChunks.current.push(event.data);
            };
            mediaRecorder.current.onstop = () => {
                const audioBlob = new Blob(audioChunks.current, { type: 'audio/m4a' });
                const audioUrl = URL.createObjectURL(audioBlob);
                const audioFile = new File([audioBlob], 'recording.m4a', { type: 'audio/m4a' });
                setSelectedFile(audioFile);
                // console.log('Audio File:', audioFile);
                audioChunks.current = [];
            };
            mediaRecorder.current.start();
            setIsRecording(true);
        }
    };

    return (
        <div className="flex justify-center items-center h-full">
            <button
                onClick={toggleRecording}
                className={`w-16 h-16 rounded-full ${isRecording ? 'bg-red-500' : 'bg-green-500'}`}
            >
                {isRecording ? 'Stop' : 'Start'}
            </button>
            {selectedFile && (
                <div className="mt-4">
                    <p>Recording saved as {selectedFile.name}</p>
                    <audio controls src={URL.createObjectURL(selectedFile)} />
                </div>
            )}
        </div>
    );
};

export default MicrophoneRecorder;
