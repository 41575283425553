import React from 'react'

const SideBarExpertise = ({ isLawChecked, isHealthChecked, isFinanceChecked, isStocksChecked, handleExpertiseCheckBoxChange }) => {
    return (
        <div className='sidebar-expertise-container'>
            <div className="sidebar-expertise-title">
                Expertise
            </div>
            <div className="sidebar-expertises">
                {/* Dynamically render based on `expertise/get` */}
                <div className="sidebar-expertise-row">
                    {isLawChecked !== null && (
                        <div className="sidebar-expertise">
                            <label htmlFor='law'>Law</label>
                            <input type="checkbox" id='law' checked={isLawChecked} onChange={() => handleExpertiseCheckBoxChange('law', isLawChecked)}
                            />
                        </div>
                    )}
                    {isHealthChecked !== null && (
                        <div className="sidebar-expertise">
                            <label htmlFor="health">Health</label>
                            <input type="checkbox" id='health' checked={isHealthChecked} onChange={() => handleExpertiseCheckBoxChange('health', isHealthChecked)}
                            />
                        </div>
                    )}
                </div>
                <div className="sidebar-expertise-row">
                    {isFinanceChecked !== null && (
                        <div className="sidebar-expertise">
                            <label htmlFor="finance">Finance</label>
                            <input type="checkbox" id='finance' checked={isFinanceChecked} onChange={() => handleExpertiseCheckBoxChange('finance', isFinanceChecked)}
                            />
                        </div>
                    )}
                    {isStocksChecked !== null && (
                        <div className="sidebar-expertise">
                            <label htmlFor="stocks">Stocks</label>
                            <input type="checkbox" id='stocks' checked={isStocksChecked} onChange={() => handleExpertiseCheckBoxChange('stocks', isStocksChecked)}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SideBarExpertise