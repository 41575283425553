import React from 'react'
import { FaPaperPlane } from 'react-icons/fa';
import { RxCrossCircled } from "react-icons/rx";
import { ReactComponent as Loading } from '../images/loading.svg';
import { ReactComponent as Plus } from '../images/plus.svg';
import { ReactComponent as Microphone } from '../images/microphone.svg';
import MicrophoneRecorder from './MicrophoneRecorder';
import ReactModal from 'react-modal';


const InputBox = ({ input, handleInputChange, selectedFile, getFileIcon, handleFileChange, isLoadingFile, isModalOpen, closeModal, setSelectedFile, handleSendFile, handleSend, isLoading, handleRecording }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  }
  return (
    <div className='inputbox-container'>
      <div className='inputbox'>
        <div className='main-input-container'
        >
          <input
            id='input-box'
            className='main-input'
            type="text"
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Ask me anything bhai... "
          />
          {
            selectedFile && (
              <div className='selected-file'>
                {getFileIcon(selectedFile)}
                <span className="ml-2 truncate max-w-[200px]">{selectedFile.name}</span>
              </div>)
          }
          <input
            type="file"
            id="fileInput"
            className="fileinput"
            accept='image/*,application/pdf,audio/*'
            onChange={handleFileChange}
          />
          <label htmlFor="fileInput" className="fileinput-icon">
            {isLoadingFile ? (
              <Loading className="animate-spin w-6 h-6 text-current hover:text-secondary1" />
            ) : selectedFile ? (
              <div className='cancel-file-selection' onClick={() => setSelectedFile(null)}>
                <RxCrossCircled size={20} />
              </div>
            ) : (
              <Plus className="w-6 h-6 text-current hover:text-secondary1" />
            )}
          </label>
        </div>
        <ReactModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Microphone Recorder Modal"
          ariaHideApp={false}
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '500px',
              height: '500px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '10px',
            },
          }}
        >
          <MicrophoneRecorder selectedFile={selectedFile} setSelectedFile={setSelectedFile} closeModal={closeModal} />
          {/* <button onClick={closeModal}>Close</button> */}
        </ReactModal>

        <button
          onClick={selectedFile ? handleSendFile : handleSend}
          className='microphone-button'
        >
          {input || selectedFile ? <FaPaperPlane /> : isLoading ? <Loading /> : <Microphone onClick={handleRecording} />}
        </button>
      </div>
    </div>
  )
}

export default InputBox;