import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const handleLogin = async (e) => {
    // console.log('Login button pressed');
    e.preventDefault();
    if (username === '' || password === '') {
      alert('Please enter all the fields bhai!');
      return;
    }
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetch('https://production.terabh.com/auth/login', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        // console.log(response);
        setIsAuthenticated(false);
        setError(response.statusText)
        return;
      }

      const data = await response.json();
      console.log('Response Data:', data);

      // Check if access_token and refresh_token are present in the response
      if (data.access_token && data.refresh_token) {
        localStorage.setItem('username', username);
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('refresh_token', data.refresh_token);
        setIsAuthenticated(true);
        navigate('/layout'); // Redirect to the layout page upon successful login
      } else {
        setError('Invalid response data');
        setIsAuthenticated(false);
      }
    } catch (err) {
      console.error('Catch Error:', err);
      setError(err.message);
      setIsAuthenticated(false);
    }
  }
  return (
    <div className='login-container'>
      <div className='login-header'>
        <div className='title'>
          <img className='title-image' src={require('../images/logo.png')} alt="Logo" />
          <div className='title-text'>Terabh<span>AI</span></div>
        </div>
        <div className='login-header-buttons'>
          <button className='login-header-login' onClick={() => navigate('/login')}>Login</button>
          <button className='login-header-signup' onClick={() => navigate('/signup')}>Signup</button>
        </div>
      </div>
      <div className='login-body'>
        <div className='welcome-text'>
          Welcome to Terabh<span>AI</span>
        </div>
        <div className='login-text'>Login to your Account!</div>
        <div className='login-form'>
          <form onSubmit={handleLogin}>
            <div className='form-group'>
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                placeholder='Enter your username'
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                placeholder='Enter your password'
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className='login-button'>
              <button type="submit">Login</button>
            </div>
            {error && <div className="error-message">{error}</div>}
            <div className="signup-instead">
              Don't have an account? <a href='/signup'>Sign up</a>
            </div>
            <div className="forgot-password">
              Forgot Password? <button onClick={() => navigate('/resetpassword')}>Reset Password</button>
            </div>
          </form>
        </div>
        <div className="terabh-text">
          <img src={require('../images/terabh-text.png')} alt="terabh-text" />
        </div>
      </div>
    </div>
  )
}

export default Login