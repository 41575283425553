import React, { useState,useEffect } from 'react';
import RevealConsent from './RevealConsent';
import { getProposed } from '../service/api';
import PersonalisationConsent from './PersonalisationConsent';
const Navbar = ({ setMode }) => {
  const [selected, setSelected] = useState('bhai');
  const [open,setOpen] = useState(false);
  const [perso,setPerso] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [selectedProposed, setSelectedProposed] = useState([]);

  const getProposedData = async (access_token) => {
    try {
      const response = await getProposed(access_token);
      setSelectedProposed(response);
      // setDataArray(selectedProposed.user_info.length);
      if(response.length > 0){
        setDataArray(response.user_info.length);
      }
      else{
        setDataArray(selectedProposed.length);
      }
      console.log(response);
    } catch (error) {
      console.error('Error fetching proposed data:', error);
    }
  };
  useEffect(() => {
    getProposedData(localStorage.getItem('access_token'));
  },[]);

  
  const handleModeChange = (mode) => {
    setSelected(mode);
    setMode(mode);
  };
  const handleProfileView = () => {
    
  }
  const toggleOpen = () => {
    setOpen(!open);
  }
  const togglePerso=()=>{
    setPerso(!perso);
  }

  return (
    <div className='layout-navbar'>
      <div className='title'>
          <img className='title-image' src={require('../images/logo.png')} alt="Logo" />
        <div className='title-text'>
          Terabh<span>AI</span>
        </div>
      </div>
      {open && <RevealConsent toggleOpen={toggleOpen} selectedProposed={selectedProposed} setSelectedProposed={setSelectedProposed}/>}
      <div className="navbar-buttons">
        <div className="profile-button" onClick={()=>togglePerso()}>
        <img
          src={require('../images/navbar-profile-logo.png')}
          alt='View Profile'
        />
      </div>
      <div className="reveal-consent-button" onClick={()=>toggleOpen()}>
        <img
          src={require('../images/navbar-reveal-consent.png')}
          alt='Reveal Consent'
        />
      </div>
        {dataArray > 0 && (
        <span className="absolute -translate-x-3.5 -translate-y-3  bg-red-500 text-white rounded-full px-1.5 py-0.5 text-xs font-bold">
          {dataArray}
        </span>
      )}


        {(open && <RevealConsent toggleOpen={toggleOpen} selectedProposed={selectedProposed} setSelectedProposed={setSelectedProposed}/>)}
        {(perso&&<PersonalisationConsent togglePerso={togglePerso}/>)}
        <div className="reveal-bhai-buttons">
            <button className="reveal-button"
              onClick={() => handleModeChange('revealed')}
              style={{
                backgroundColor: selected === 'revealed' ? '#E76A4E' : 'white',
                color: selected === 'revealed' ? 'white' : 'black'
              }}
            >
              revealed
            </button>
            <button className="bhai-button"
              onClick={() => handleModeChange('bhai')}
              style={{
                backgroundColor: selected === 'bhai' ? '#E76A4E' : 'white',
                color: selected === 'bhai' ? 'white' : 'black'
              }}
            >
              bhai
            </button>
        </div>
      </div>
      </div>
  );
}

export default Navbar;
