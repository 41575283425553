import React from 'react'
import { FaArrowCircleRight, FaArrowCircleDown } from "react-icons/fa";

const SideBarFooter = ({ isDataModalOpen, isHelpCenterOpen, handleToggleDataModal, selectedSection, persistedData, revealedData, proposedPersonalizationsData, revealedWebsiteData, handleSectionClick, handleToggleHelpCenter, helpOption, helpData, handleHelpOption, handleLogoutButtonClick }) => {
    return (
        <>
            <div className='sidebar-footer'>
                <div className='sidebar-your-data'>
                    <span>Your Data</span>
                    <button onClick={() => handleToggleDataModal()}>
                        {
                            isDataModalOpen ? (<FaArrowCircleDown />) : (<FaArrowCircleRight />)
                        }
                    </button>
                </div>
                <div className='sidebar-help-center'>
                    <span>Help Center</span>
                    <button onClick={() => handleToggleHelpCenter()}>
                        {isHelpCenterOpen ? (<FaArrowCircleDown />) : (<FaArrowCircleRight />)}
                    </button>
                </div>

                <div className='sidebar-log-out'>
                    <span>Log Out</span>
                    <button onClick={() => handleLogoutButtonClick()}>
                        <FaArrowCircleRight />
                    </button>
                </div>
            </div>
            {
                isDataModalOpen && (
                    <div className="sidebar-data-modal-container">
                        <div className="sidebar-data-modal">
                            <div className="sidebar-data-modal-buttons">
                                <div className="sidebar-data-modal-buttons-row">
                                    <button className="persisted-data"
                                        onClick={() => handleSectionClick('persisted-data')}
                                        style={{ backgroundColor: selectedSection === 'persisted-data' ? '#E76A4E' : '#e76a4eac' }}
                                    >
                                        Persisted Data
                                    </button>
                                    <button className="revealed-data"
                                        onClick={() => handleSectionClick('revealed-data')}
                                        style={{ backgroundColor: selectedSection === 'revealed-data' ? '#E76A4E' : '#e76a4eac' }}
                                    >
                                        Revealed Data
                                    </button>
                                </div>
                                <div className="sidebar-data-modal-buttons-row">
                                    <button className="proposed-personalizations"
                                        onClick={() => handleSectionClick('proposed-personalizations')}
                                        style={{ backgroundColor: selectedSection === 'proposed-personalizations' ? '#E76A4E' : '#e76a4eac' }}
                                    >
                                        Personalizations
                                    </button>
                                    <button className="revealed-website"
                                        onClick={() => handleSectionClick('revealed-website')}
                                        style={{ backgroundColor: selectedSection === 'revealed-website' ? '#E76A4E' : '#e76a4eac' }}
                                    >
                                        Revealed Website
                                    </button>
                                </div>
                            </div>
                            <div className="sidebar-data-modal-content">
                                {selectedSection === 'persisted-data' && persistedData && (
                                    <div className="persisted-data-content">
                                        {persistedData.map(item => (
                                            <li key={item.message_id}>
                                                {item.content}
                                            </li>
                                        ))}
                                    </div>
                                )}

                                {selectedSection === 'revealed-data' && revealedData && (
                                    <div className="revealed-data-content">
                                        {revealedData.map(item => (
                                            <li key={item.message_id}>
                                                {item.content}
                                            </li>
                                        ))}
                                    </div>
                                )}

                                {selectedSection === 'proposed-personalizations' && proposedPersonalizationsData && (
                                    <div className="proposed-personalizations-content">
                                        {proposedPersonalizationsData.map(item => (
                                            <li key={item.info_id}>
                                                {item.info}
                                            </li>
                                        ))}
                                    </div>
                                )}

                                {selectedSection === 'revealed-website' && revealedWebsiteData && (
                                    <div className="revealed-website-content">
                                        {revealedWebsiteData.map(item => (
                                            <li key={item.info_id}>
                                                {item.info}
                                            </li>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            }
            {isHelpCenterOpen && (
                <div className="sidebar-help-center-modal-container">
                    <div className="sidebar-help-center-modal">
                        <div className="sidebar-help-center-modal-buttons">
                            <button
                                onClick={() => handleHelpOption('faqs')}
                                style={{ backgroundColor: helpOption === 'faqs' ? '#E76A4E' : '#e76a4eac' }}>FAQs</button>
                            <button
                                onClick={() => handleHelpOption('contactUs')}
                                style={{ backgroundColor: helpOption === 'contactUs' ? '#E76A4E' : '#e76a4eac' }}
                            >Contact Us</button>
                        </div>
                        <div className="sidebar-help-center-modal-content">
                            {helpOption === 'faqs' && helpData && (
                                <div className='faq-modal'>
                                    {helpData.map((item, index) => (
                                        <div className='faq' key={index}>
                                            <div className="faq-question">Q: {item.question}</div>
                                            <div className="faq-answer">A: {item.answer}</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {helpOption === 'contactUs' && helpData && (
                                <div className='contactUs-modal'>
                                    <div className="contact-email">{helpData.email}</div>
                                    <div className="contact-phone">{helpData.phone}</div>
                                    <div className="contact-address">{helpData.address}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default SideBarFooter