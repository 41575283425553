import React, { useEffect, useRef, useState } from 'react';
import { LoadFile, getProfileImage, getReply, getReplyFile, loadConversation } from '../service/api';
import InitialInput from './InitialInput';
import InputBox from './InputBox';
import BhaiMessageRenderer from './bhai/BhaiMessageRenderer';

const Bhai = () => {
    const [input, setInput] = useState('');
    const [bhaiConversation, setbhaiConversation] = useState([]);
    const [timeStamp, setTimeStamp] = useState('');
    const [pastConversationExists, setPastConversationExists] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [filepayload, setFilePayload] = useState(null);
    const messagesEndRef = useRef(null);
    const prevLengthRef = useRef(bhaiConversation.length);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const loadBhaiConversation = async () => {
        try {
            const response = await loadConversation(timeStamp, localStorage.getItem('access_token'));
            console.log(response);
            if (response.length === 0) {
                setPastConversationExists(false);
                return;
            }
            let pastConversation = [];
            for (let i = response.length - 1; i >= 0; i--) {
                pastConversation.push(response[i]);
                if (i === 0) {
                    setTimeStamp(response[i].timestamp);
                }
            }

            setbhaiConversation((prevConversation) => [
                ...pastConversation,
                ...prevConversation,
            ]);
            // scrollToBottom(); // working?
        } catch (err) {
            console.error("Error loading bhai conversation: " + err.message);
        }
    };
    useEffect(() => {
        loadBhaiConversation();
        const messageIds = bhaiConversation.map(eachMessage => eachMessage.message_id)
        console.log(messageIds);
    }, []);

    useEffect(() => {
        getImage();
    }, []);

    // Scroll To Bottom only when bhaiConversation.length increases!
    useEffect(() => {
        if (bhaiConversation.length > prevLengthRef.current) {
            scrollToBottom();
        }
        prevLengthRef.current = bhaiConversation.length;
    }, [bhaiConversation.length]);

    const handleRecording = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const getImage = async () => {
        const response = await getProfileImage(localStorage.getItem('access_token'));
        console.log('User profile image:', response);
        setProfileImage(response);
    }

    // const handleScroll = async () => {
    //     const scrollContainer = scrollContainerRef.current;
    //     if (scrollContainer) {
    //         const prevScrollHeight = scrollContainer.scrollHeight;
    //         const prevScrollTop = scrollContainer.scrollTop;
    //         const clientHeight = scrollContainer.clientHeight;

    //         if (!isLoading && prevScrollTop <= prevScrollHeight * 0.2) {
    //             setIsLoading(true);

    //             const lastMessageId = Math.min(...messages.map((msg) => msg.message_id));
    //             const lastMessage = messages.find((msg) => msg.message_id === lastMessageId);
    //             // const timestampForLastMessage = lastMessage.timestamp;
    //             const timestampForLastMessage = '';

    //             try {
    //                 const response = await loadConversation(timestampForLastMessage, localStorage.getItem('access_token'));
    //                 const sortedResponse = [...response].reverse();

    //                 // Calculate the height of the new messages
    //                 const tempContainer = document.createElement('div');
    //                 sortedResponse.forEach((msg) => {
    //                     const messageElement = document.createElement('div');
    //                     messageElement.textContent = msg.content; // Adjust as per your message structure
    //                     tempContainer.appendChild(messageElement);
    //                 });
    //                 document.body.appendChild(tempContainer);
    //                 const newMessagesHeight = tempContainer.scrollHeight;
    //                 document.body.removeChild(tempContainer);

    //                 // Update message state
    //                 setMessages((prevMessages) => {
    //                     const newMessages = [...sortedResponse, ...prevMessages];
    //                     return newMessages;
    //                 });

    //                 // Adjust the scroll position after new messages are added
    //                 const newScrollHeight = scrollContainer.scrollHeight;
    //                 scrollContainer.scrollTop = newScrollHeight - prevScrollHeight + prevScrollTop + newMessagesHeight;
    //             } catch (error) {
    //                 console.error('Error loading conversation:', error);
    //             } finally {
    //                 setIsLoading(false);
    //             }
    //         }
    //     }
    // };

    const handleSend = async () => {
        // console.log('Sending message:', input);
        const dummyMessage = {
            "user_id": "dummy",
            "origin": "user",
            "timestamp": "dummy",
            "content": input,
            "message_id": Number.MAX_SAFE_INTEGER,
            "reveal": false,
            "modal": "text",
            "location": null,
            "active": true,
            "persist": false,
            "plugins_used": [],
            "like_status": "neutral"
        };
        setbhaiConversation([...bhaiConversation, dummyMessage]);
        setIsLoading(true);
        if (input.trim() === '') return;
        const response = await getReply({ message: input }, localStorage.getItem('access_token'));
        console.log(response);
        setbhaiConversation((prevConversation) => prevConversation.filter((eachMessage) => {
            return eachMessage.user_id !== "dummy";
        }))
        setbhaiConversation([...bhaiConversation, ...response.messages]);
        setIsLoading(false);
        const deactivateMessages = response.deactivated_message_ids;
        for (let i = 0; i < deactivateMessages.length; i++) {
            setbhaiConversation((prevConversation) =>
                prevConversation.map((eachMessage) =>
                    eachMessage.message_id === deactivateMessages[i].message_id
                        ? { ...eachMessage, active: false }
                        : eachMessage
                )
            );
        }

        setInput('');
        scrollToBottom();
    };

    const handleSendFile = async () => {
        // console.log('Sending file:', selectedFile);
        // console.log(input, selectedFile);
        setIsLoadingFile(true);
        if (selectedFile === null) return;
        const newConversation = [...bhaiConversation, { content: input, origin: 'user', modal: getModality(selectedFile) }];
        setbhaiConversation(newConversation);
        let payload = filepayload;
        if (payload === null) {
            payload = {
                modality: getModality(selectedFile),
                message: input,
                file: selectedFile,
            }
        }
        setInput('');
        setSelectedFile(null);
        const response = await getReplyFile(payload, localStorage.getItem('access_token'));
        setTimeout(() => {
            setIsLoadingFile(false); // should be true or false?
        }, 2000);
        setIsLoadingFile(false);
        setIsLoading(false);
        setbhaiConversation([...bhaiConversation, ...response]);
    };

    const getModality = (file) => {
        if (file.type.startsWith('image/')) {
            return 'image';
        } else if (file.type === 'application/pdf') {
            return 'document';
        } else if (file.type.startsWith('audio/')) {
            return 'audio';
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file && (file.type.startsWith('image/') || file.type === 'application/pdf' || file.type.startsWith('audio/'))) {
            setSelectedFile(file);
            // setIsLoading(true);
            const modality = getModality(file);
            const payload = {
                modality: modality,
                message: input,
                file: file,
            };
            setFilePayload(payload);
        }
        else {
            alert('Please select a valid file (image, audio, or PDF)');
        }
    }

    const getFileIcon = (file) => {
        if (file.type.startsWith('image/')) {
            return (
                <img src={URL.createObjectURL(file)} alt="File" className="w-12 h-12 object-cover rounded-lg" />
            );
        } else if (file.type === 'application/pdf') {
            return (
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.25 0H0.75C0.3375 0 0 0.3375 0 0.75V11.25C0 11.6625 0.3375 12 0.75 12H11.25C11.6625 12 12 11.6625 12 11.25V0.75C12 0.3375 11.6625 0 11.25 0ZM10.5 1.5V3H9V1.5H10.5ZM9 4.5V3.75C9 3.3375 8.6625 3 8.25 3H4.5V1.5H3V4.5H9ZM3 10.5V6H8.25C8.6625 6 9 6.3375 9 6.75V10.5H3ZM10.5 10.5H9V9H10.5V10.5ZM10.5 8.25H9V6.75C9 6.3375 9.3375 6 9.75 6H10.5V8.25ZM7.5 7.5H6V6H7.5V7.5ZM7.5 9V7.5H6V9H7.5Z" fill="#F5F5F5" />
                </svg>
            );
        } else if (file.type.startsWith('audio/')) {
            return (
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6C12 2.688 9.312 0 6 0ZM6 10.5C3.75 10.5 1.5 8.25 1.5 6C1.5 3.75 3.75 1.5 6 1.5C8.25 1.5 10.5 3.75 10.5 6C10.5 8.25 8.25 10.5 6 10.5ZM8.25 5.25H5.25V6.75H8.25V5.25ZM6 1.875C4.6885 1.875 3.75 2.8135 3.75 4.125V7.875C3.75 9.1865 4.6885 10.125 6 10.125C7.3115 10.125 8.25 9.1865 8.25 7.875H9.75C9.75 10.3125 7.6875 12.375 5.25 12.375C2.8125 12.375 0.75 10.3125 0.75 7.875C0.75 5.4375 2.8125 3.375 5.25 3.375C5.4375 3.375 5.625 3.375 5.8125 3.375C5.8125 3.375 5.8125 3.375 6 3.375C6.1875 3.375 6.375 3.375 6.375 3.375C6.5625 3.375 6.75 3.375 6.9375 3.375C6.9375 3.375 6.9375 3.375 7.125 3.375C7.3125 3.375" fill='ravi' />
                </svg>
            );
        }
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const downloadFile = (base64file, filename) => {
        const link = document.createElement('a');
        link.href = base64file;
        link.download = filename;
        link.click();
    };

    const handleDownload = async (message_id, location, modal) => {
        const fileName = location.split('/').pop();
        setIsLoading(true);
        const response = await LoadFile({ message_id: message_id, filename: fileName }, localStorage.getItem('access_token'));
        if (response) {
            downloadFile(response.return, response.filename);
        }
        setIsLoading(false);
    };
    return (
        <div className='bhai'>
            {
                bhaiConversation.length === 0 ? (
                    <InitialInput setInput={setInput} handleSend={handleSend} />
                ) :
                    (
                        <>
                            <div className="bhai-conversation">
                                {bhaiConversation.map((eachMessage, index) => (
                                    <BhaiMessageRenderer
                                        key={eachMessage.message_id}
                                        message={eachMessage}
                                        profileImage={profileImage}
                                        setbhaiConversation={setbhaiConversation}
                                        handleDownload={handleDownload}
                                        isLastMessage={index === bhaiConversation.length - 1}
                                    />
                                ))}
                                <div ref={messagesEndRef} />
                            </div>
                        </>
                    )
            }
            <InputBox
                input={input}
                handleInputChange={handleInputChange}
                selectedFile={selectedFile}
                getFileIcon={getFileIcon}
                handleFileChange={handleFileChange}
                isLoadingFile={isLoadingFile}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                setSelectedFile={setSelectedFile}
                handleSendFile={handleSendFile}
                handleSend={handleSend}
                isLoading={isLoading}
                handleRecording={handleRecording}
            />
        </div>
    );
};

export default Bhai;
