import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { postTokenData } from '../service/api';

const OAuthCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const error_code = params.get('error');
        const scope = params.get('scope');
        const service = scope.split('/')[4];

        if (error_code === 'access_denied') {
            localStorage.setItem(`${service}_consent`, 'false');
            navigate('/layout');
            return;
        }

        const requestBody = {
            client_type: 'web',
            service: service,
            redirect_uri: 'http://localhost:3000/callback',
            access_code: code,
        };

        const getToken = async () => {
            const data = await postTokenData(localStorage.getItem('access_token'), requestBody);
            if (data === 'Token saved successfully') {
                localStorage.setItem(`${service}_consent`, 'true');
                navigate('/layout');
            }
        };
        if (code) {
            getToken();
            // navigate('/layout');
        }
    }, [location.search]);
};

export default OAuthCallback;
