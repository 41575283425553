import React, { useState,useEffect,useRef  } from 'react';

import axios from 'axios'
const Modal = ({togglePerso}) => {
 
  const [proposedPersonalizations, setProposedPersonalizations] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState("");
  const [currentID,setCurrentID]=useState();
 
  const buttonStyle = {
    background: 'white',
    
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 5px'
  };
  const getUniqueItems = (data) => {
    const uniqueDescriptions = new Set();
    return data.filter(item => {
      if (uniqueDescriptions.has(item.info)) {
        return false;
      } else {
        uniqueDescriptions.add(item.info);
        return true;
      }
    });
  };
  const handleAccept = async (infoId) => {
    try {
      const bearerToken = localStorage.getItem("access_token");
      await axios.post(`https://appbackend.terabh.com/personalization/update_status`, {
        info_id:infoId,
        status: 'accepted',
      }, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
        },
      });
      // After successful update, fetch proposed personalizations again to reflect changes
      fetchData();
    } catch (error) {
      console.error('Error accepting personalization:', error);
    }
  };

  const handleDelete = async (infoId) => {
    try {
      // Implement the delete functionality if needed
      const bearerToken = localStorage.getItem("access_token");
      await axios.post(`https://appbackend.terabh.com/personalization/update_status`, {
        info_id:infoId,
        status: 'rejected',
      }, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
        },
      });
      // After successful update, fetch proposed personalizations again to reflect changes
      fetchData();
      console.log(`Deleting personalization with info ID ${infoId}`);
    } catch (error) {
      console.error('Error deleting personalization:', error);
    }
  };
  const startEdit=(id)=>{
    setIsEditing(!isEditing);
    setCurrentID(id);
  }
  const handleEdit = async (infoId) => {
    try {
      // Implement the delete functionality if needed
      
      const bearerToken = localStorage.getItem("access_token");
      await axios.post(`https://appbackend.terabh.com/personalization/edit`, {
        info_id:infoId,
        new_info :editContent,
      }, {
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
        },
      });
      // After successful update, fetch proposed personalizations again to reflect changes
      fetchData();
      setEditContent('');
      setIsEditing(false)
      console.log(`Editing  personalization with info ID ${infoId}`);
    } catch (error) {
      console.error('Error editing personalization:', error);
    }
  };
  

  const modalRef = useRef(null);
 
  useEffect(() => {
    fetchData();
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        togglePerso();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [togglePerso]);
  // const sections = Array(6).fill('About');
 
  
  const fetchData = async () => {
    try {
      const bearerToken = localStorage.getItem("access_token");
      const response = await axios.get('https://production.terabh.com/personalization/proposed', {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });
      const uniqueItems = getUniqueItems(response.data);
      setProposedPersonalizations(uniqueItems);
      console.log("personalisation data",response.data);
    } catch (error) {
      console.error('Error fetching proposed personalizations:', error);
    }
  };
  const fetchExtractedData = async () => {
    try {
      const bearerToken = localStorage.getItem("access_token");
      const response = await fetch('https://appbackend.terabh.com/personalization/extract', {
        headers: {
          'Authorization': `Bearer ${bearerToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch extracted data');
      }

      const data = await response.json();
      const uniqueItems = getUniqueItems(data);
      console.log("this is extracted data",uniqueItems);
      setProposedPersonalizations(prevItems => [...prevItems, ...uniqueItems]);
    } catch (error) {
      console.error("Error fetching extracted data:", error);
    }
  };
  return (
    <div className="fixed inset-0 z-50 flex items-start justify-end pr-4 pt-4 m-0 " style={{ marginLeft: '0px' }}>
    <div className="absolute inset-0 bg-black w-full  opacity-60 "></div>
    <div ref={modalRef} className="relative bg-newBackground text-white rounded-lg shadow-lg p-6 w-80 h-190 mt-4 -translate-x-80 overflow-y-auto max-h-96">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold font-helvetica" >Personalisation consent</h2>
        <button onClick={fetchExtractedData} className="p-2 bg-blue-500 rounded-full hover:bg-blue-700">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="28" height="28" viewBox="0 0 30 30" style={{fill:"white"}}>
<path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"></path>
</svg>
        </button>
        {/* <button onClick={togglePerso}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#E76A4E" />
            <path d="M8.625 7.78125C8.40122 7.78125 8.18661 7.87015 8.02838 8.02838C7.87015 8.18661 7.78125 8.40122 7.78125 8.625V15.375C7.78125 15.5988 7.87015 15.8134 8.02838 15.9716C8.18661 16.1299 8.40122 16.2188 8.625 16.2188H15.375C15.5988 16.2188 15.8134 16.1299 15.9716 15.9716C16.1299 15.8134 16.2188 15.5988 16.2188 15.375V14.9531C16.2188 14.6175 16.3521 14.2955 16.5894 14.0582C16.8268 13.8208 17.1487 13.6875 17.4844 13.6875C17.82 13.6875 18.142 13.8208 18.3793 14.0582C18.6167 14.2955 18.75 14.6175 18.75 14.9531V15.375C18.75 16.2701 18.3944 17.1285 17.7615 17.7615C17.1285 18.3944 16.2701 18.75 15.375 18.75H8.625C7.72989 18.75 6.87145 18.3944 6.23851 17.7615C5.60558 17.1285 5.25 16.2701 5.25 15.375V8.625C5.25 7.72989 5.60558 6.87145 6.23851 6.23851C6.87145 5.60558 7.72989 5.25 8.625 5.25H9.04688C9.38254 5.25 9.70446 5.38334 9.94181 5.62069C10.1792 5.85804 10.3125 6.17996 10.3125 6.51562C10.3125 6.85129 10.1792 7.17321 9.94181 7.41056C9.70446 7.64791 9.38254 7.78125 9.04688 7.78125H8.625ZM13.2656 7.78125C12.93 7.78125 12.608 7.64791 12.3707 7.41056C12.1333 7.17321 12 6.85129 12 6.51562C12 6.17996 12.1333 5.85804 12.3707 5.62069C12.608 5.38334 12.93 5.25 13.2656 5.25H17.4844C17.82 5.25 18.142 5.38334 18.3793 5.62069C18.6167 5.85804 18.75 6.17996 18.75 6.51562V10.7344C18.75 11.07 18.6167 11.392 18.3793 11.6293C18.142 11.8667 17.82 12 17.4844 12C17.1487 12 16.8268 11.8667 16.5894 11.6293C16.3521 11.392 16.2188 11.07 16.2188 10.7344V9.57L14.16 11.6288C13.9201 11.8523 13.6028 11.974 13.2749 11.9682C12.947 11.9624 12.6341 11.8296 12.4023 11.5977C12.1704 11.3659 12.0376 11.053 12.0318 10.7251C12.026 10.3972 12.1477 10.0799 12.3712 9.84L14.43 7.78125H13.2656Z" fill="#F5F5F5" />
          </svg>
        </button> */}

      </div>
      <div className="space-y-20">
        <div className='space-y-2'>
         
             {proposedPersonalizations &&
                        proposedPersonalizations.map(item => (
                          <div key={item.info_id}>
                            <li>{item.info}</li>
                            <div style={{display:"flex",paddingLeft:"194px"}}>
                           
                            <button onClick={() => handleAccept(item.info_id)} style={buttonStyle}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="11.5" stroke="black"/>
          <path d="M6 12L10 16L18 8" stroke="black" strokeWidth="2"/>
        </svg>
      </button>
      <button onClick={() => handleDelete(item.info_id)} style={buttonStyle}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="11.5" stroke="black"/>
          <path d="M8 8L16 16M8 16L16 8" stroke="black" strokeWidth="2"/>
        </svg>
      </button>
      <button onClick={()=>{startEdit(item.info_id)}} style={buttonStyle}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="11.5" stroke="black"/>
          <path d="M8 16L16 8" stroke="black" strokeWidth="2"/>
        </svg>
      </button>
      
        
      </div>{isEditing&& (item.info_id==currentID)&&(
        <div style={{display:"inline-block"}}>
          <input onChange={(e)=>{setEditContent(e.target.value)}} style={{color:"black"}}></input>&nbsp;
          <button type='submit'  onClick={()=>{handleEdit(item.info_id)}} style={{ color: "rgb(231,106,78" }}>Done!</button>
        </div>
      )}</div>))}
        </div>

      </div>
    </div>
  </div>
);
  
};

export default Modal;