import React from 'react'
import PluginDescriptionModal from './PluginDescriptionModal'

const PluginCheckBox = ({ id, label, icon, isChecked, handlePluginCheckBoxChange, handleToggleDescription, description, isClicked }) => {
    return (
        <div className='plugin-check-box'>
            <div className="plugin-row">
                <div className="plugin-image">
                    <img
                        src={icon}
                        alt="Plugin icon"
                        onClick={handleToggleDescription}
                    />
                    <label htmlFor={id}>
                        {label}
                    </label>
                </div>
                <div className="plugin-input">
                    <input type="checkbox" id={id} checked={isChecked} onChange={handlePluginCheckBoxChange} />
                </div>
            </div>
            {isClicked && (
                <PluginDescriptionModal
                    label={label}
                    handleToggleDescription={handleToggleDescription}
                    description={description}
                />
            )}
        </div>
    )
}

export default PluginCheckBox