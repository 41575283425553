import React, { useState, useEffect } from 'react';
import UserList from './UserList';
import RevealedChat from './RevealedChat';
import SearchBar from './SearchBar';
import { fetchSearchResults, fetchUserChats } from '../service/api';

const RevealedScreen = () => {
  const [usernames, setUsernames] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [loading,setLoading] = useState(true);
  // {username:'user1'}, {username:'user2'}, {username:'user3'}, {username:'user4'}, {username:'user5'}
  useEffect(() => {
    fetchUsernames();
    
  }, []);

  useEffect(() => {
    const timer = setTimeout(async() => {
      setDebouncedSearchTerm(searchTerm);
      if (searchTerm) {
        const response = await fetchSearchResults(searchTerm);
        setSearchResults(response);
      } else {
        setSearchResults([]);
      }
    }, 500); // Adjust the debounce delay as needed

    return () => {
      clearTimeout(timer);
    };
  }, [searchTerm]);

  const fetchUsernames = async () => {
    try {
        const response = await fetchUserChats(localStorage.getItem('access_token'));
        setUsernames(response);
        setLoading(false);
    } catch (error) {
      console.error('Error fetching usernames:', error);
    }
  };


  const handleUsernameClick = (username) => {
    setSelectedUsername(username);
    setShowSearchBar(false);
  };

  const handleBackClick = () => {
    setSelectedUsername(null);
    setShowSearchBar(true);
  };

  return (
    <div className="p-4">
      {showSearchBar && <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />}
      {selectedUsername ? (
        <RevealedChat username={selectedUsername} onBackClick={handleBackClick} />
      ) : searchTerm ? (
        <UserList usernames={searchResults} onUsernameClick={handleUsernameClick} loading={loading}/>
      ) : usernames ? (
        <UserList usernames={usernames} onUsernameClick={handleUsernameClick} loading={loading}/>
      ) : <div className='text-white'>Loading</div>}
    </div>
  );
};

export default RevealedScreen;