import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OTPModal from './OTPModal';

const Signup = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState(null);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [error, setError] = useState('');

  const handleVerifyOTP = async () => {
    if (otp === null || otp < 1000 || otp > 9999) {
      alert('Enter valid OTP bhai!');
      return;
    }
    try {
      const formData = {
        "otp": Number(otp),
        "email": email,
        "username": username,
        "password": password
      };
      const response = await fetch('https://production.terabh.com/auth/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Signup failed!');
      }

      const data = await response.json();
      // console.log(data);
      // Handle the response data (e.g., save token, redirect user)
      localStorage.setItem('username', username);
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      setIsAuthenticated(true);
      setError('');
      navigate('/layout'); // Redirect to the layout page upon successful signup and verified OTP
    } catch (error) {
      setError('Signup failed.');
      setIsAuthenticated(false);
      setShowOTPModal(false);
    }
  }
  const sendEmailOTP = async () => {
    try {
      const url = `https://production.terabh.com/auth/get_email_otp?email=${email}`;
      const response = await fetch(url, {
        method: 'POST',
      });
      const data = await response.json();
      alert(data.detail);
    }
    catch (err) {
      alert('Error sending OTP: ', err);
    }
  }
  const handleSignup = async (e) => {
    console.log('Signup button pressed');
    e.preventDefault();
    if (email === '' || username === '' || password === '') {
      alert('Please enter all the fields!');
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address!');
      return;
    }
    await sendEmailOTP();
    setShowOTPModal(true);
    setOtp('');
  };

  return (
    <div className='signup-container'>
      <div className='signup-header'>
        <div className='title'>
          <img className='title-image' src={require('../images/logo.png')} alt="Logo" />
          <div className='title-text'>Terabh<span>AI</span></div>
        </div>
        <div className='signup-header-buttons'>
          <button className='signup-header-login' onClick={() => navigate('/login')}>Login</button>
          <button className='signup-header-signup' onClick={() => navigate('/signup')}>Signup</button>
        </div>
      </div>
      <div className='signup-body'>
        <div className='welcome-text'>
          Welcome to Terabh<span>AI</span>
        </div>
        <div className='signup-text'>Create an Account to get started!</div>
        <div className='signup-form'>
          <form onSubmit={handleSignup}>
            <div className='form-group'>
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                value={email}
                placeholder='Enter your email'
                onChange={(e) => { setEmail(e.target.value); setError(''); }}
              />
            </div>
            <div className='form-group'>
              <label htmlFor="username">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                placeholder='Enter your username'
                onChange={(e) => { setUsername(e.target.value); setError(''); }}
              />
            </div>
            <div className='form-group'>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                placeholder='Enter your password'
                onChange={(e) => { setPassword(e.target.value); setError(''); }}
              />
            </div>
            <div className='signup-button'>
              <button type="submit">Create an Account</button>
            </div>
            {error && <div className="error-message">{error}</div>}
            <div className="login-instead">
              Already have an account? <a href='/login'>Login</a>
            </div>
          </form>
        </div>
        {showOTPModal && <OTPModal
          otp={otp}
          setOtp={setOtp}
          setShowOTPModal={setShowOTPModal}
          handleVerifyOTP={handleVerifyOTP}
        />}
        <div className="terabh-text">
          <img src={require('../images/terabh-text.png')} alt="terabh-text" />
        </div>
      </div>
    </div>
  )
}

export default Signup