import React from 'react'
import { RiCloseLine } from 'react-icons/ri';
import ReactMarkdown from 'react-markdown';

const PluginDescriptionModal = ({ label, handleToggleDescription, description }) => {
    return (
        <>
            <div className='modal-outside-container'
            onClick={handleToggleDescription}
            />
            <div className="modal-container"

            >
                <div className="plugin-description-modal-header">
                    <div className="modal-title">
                        {label}
                    </div>
                    <button className="modal-close-icon" onClick={handleToggleDescription}>
                        <RiCloseLine />
                    </button>
                </div>
                <div className="plugin-description-modal-content">
                    <ReactMarkdown>{description}</ReactMarkdown>
                </div>
            </div>
        </>
    )
}

export default PluginDescriptionModal