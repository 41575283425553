import React from 'react';
import { RiCloseLine } from 'react-icons/ri';

const OTPModal = ({ otp, setOtp, setShowOTPModal, handleVerifyOTP }) => {
  const handleOTPChange = (e) => {
    setOtp(e.target.value);
  };

  return (
    <>
      <div className="modal-outside-container" onClick={() => { setShowOTPModal(false); setOtp(''); }} />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title">
            We have sent an OTP to your email. Please enter it to confirm creating an account.
          </div>
          <button className="modal-close-icon" onClick={() => { setShowOTPModal(false); setOtp(''); }}>
            <RiCloseLine />
          </button>
        </div>
        <div className="modal-content">Enter OTP:</div>
        <div className="modal-input-container">
          <input
            type='text'
            inputMode="numeric"
            maxLength="4"
            value={otp}
            onChange={(e) => handleOTPChange(e)}
          />
        </div>
        <div className="modal-buttons">
          <button className="modal-verify-button" onClick={() => { setShowOTPModal(false); handleVerifyOTP(); }}>
            Verify
          </button>
          <button className="modal-cancel-button" onClick={() => { setShowOTPModal(false); setOtp(''); }}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default OTPModal;
