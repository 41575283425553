import axios from "axios"
const url = 'https://production.terabh.com/'

export const getReply = async (data, access_token) => {
    // console.log(data, access_token);
    try {
        const response = await axios.post(url + 'bhai/reply_text', data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data
    } catch (error) {
        console.error(error)
    }
}


export const likeMessage = async (data, access_token) => {
    // console.log(data, access_token);
    try {
        const response = await axios.put(url + 'bhai/like', data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const loadConversation = async (timeStamp, access_token) => {
    try {
        const response = await axios.get(url + 'bhai/load?timestamp=' + timeStamp,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const ChangeMessageState = async (data, access_token) => {
    // console.log(data, access_token);
    try {
        const response = await axios.put(url + 'bhai/change_state', data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const getProfileImage = async (access_token) => {
    try {
        const response = await axios.get(url + 'profile/get',
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        const username = response.data.username;
        const response2 = await axios.get(url + `profile/get_profile_pic?username=${username}`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                responseType: 'arraybuffer'
            })

        const base64Image = btoa(
            new Uint8Array(response2.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        return 'data:image/png;base64,' + base64Image;
    }
    catch (error) {
        console.error(error)
    }
}

export const deleteMessage = async (data, access_token) => {
    try {
        const response = await axios.delete(url + 'bhai/delete?message_id=' + data.message_id,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const getReplyFile = async (data, access_token) => {
    // console.log(data, access_token);
    try {
        const response = await axios.post(url + 'bhai/reply_file', data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    "Content-Type": "multipart/form-data",
                },
            })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const regenerateResponse = async (access_token) => {
    console.log(access_token);
    try {
        const response = await axios.post(url + 'bhai/regenerate', {},
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const loadRevealedMessages = async (access_token) => {
    try {
        const response = await axios.get(url + 'profile/get',
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        const username = response.data.username;
        const data = {
            "username": username,
            "timestamp": null
        };
        const response2 = await axios.post(url + 'revealed/load', data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        console.log(response2);
        return response2.data

    } catch (error) {
        console.error(error)
    }
}

export const LoadFile = async (data, access_token) => {
    try {
        const response = await axios.get(url + 'bhai/load_file?message_id=' + data.message_id,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                responseType: 'arraybuffer'
            });
        const filename = data.filename;
        const extension = filename.split('.')[1];
        const base64File = btoa(
            new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        let responseObject = {
            return: null,
            filename: null
        }
        if (extension === 'pdf') {
            responseObject.return = 'data:application/pdf;base64,' + base64File;
            responseObject.filename = filename;
        }
        else if (extension === 'jpg' || extension === 'jpeg') {
            responseObject.return = 'data:image/jpeg;base64,' + base64File;
            responseObject.filename = filename;
        }
        else if (extension === 'png') {
            responseObject.return = 'data:image/png;base64,' + base64File;
            responseObject.filename = filename;
        }
        else if (extension === 'mp4') {
            responseObject.return = 'data:video/mp4;base64,' + base64File;
            responseObject.filename = filename;
        }
        else if (extension === 'mp3') {
            responseObject.return = 'data:audio/mp3;base64,' + base64File;
            responseObject.filename = filename;
        }
        else if (extension === 'docx') {
            responseObject.return = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + base64File;
            responseObject.filename = filename;
        }
        else if (extension === 'xlsx') {
            responseObject.return = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + base64File;
            responseObject.filename = filename;
        }
        else if (extension === 'pptx') {
            responseObject.return = 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,' + base64File;
            responseObject.filename = filename;
        }
        else {
            responseObject.return = 'data:application/octet-stream;base64,' + base64File;
            responseObject.filename = filename;
        }
        return responseObject;
    } catch (error) {
        console.error(error)
    }
}
export const replyRevealedMessage = async (data, access_token) => {
    try {
        const response = await axios.post(url + 'revealed/reply', data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data

    } catch (error) {
        console.error(error)
    }
}

export const fetchSearchResults = async (searchTerm) => {
    try {
        // Replace this with your actual API call to search for users
        const response = await axios.get(url + `revealed/search?query=${searchTerm}`);
        const data = await response.data;
        return data;
    } catch (error) {
        console.error('Error fetching search results:', error);
    }
};
export const fetchUserChats = async (access_token) => {
    try {
        const response = await axios.get(url + 'revealed/latest_message', {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });
        const data = await response.data;
        console.log("fetchedUserMessages", data);
        return data;
    } catch (error) {
        console.error('error fetching in latest chats', error);
    }
};

export const getProfile = async (access_token) => {
    try {
        const response = await axios.get(url + 'profile/get',
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data.username;
    } catch (error) {
        console.error("error fetching profile name", error);
    }

};

export const check_token = async (service, access_token) => {
    try {
        const response = await axios.get(url + `plugin/${service}/check_token`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        if (response.status === 200) {
            return true;
        }
        return false;
        // return response.data;
    } catch (error) {
        console.error("error fetching check_token status", error);
    }
}

export const getProposed = async (access_token) => {
    try {
        const response = await axios.get(url + 'rw/user_info/proposed',
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        const data = await response.data;
        console.log("proposed", response.data);
        return data;
    } catch (error) {
        console.error("error fetching proposed", error);
    }
};

export const getAuthURL = async (service, redirect_uri, access_token) => {
    const client = 'web';
    try {
        const response = await axios.post(url + `plugin/auth_url/${service}/${client}`,
            {
                uri: redirect_uri
            },
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data;
    }
    catch (error) {
        console.error("error fetching auth_url", error);
    }
}

export const getStatus = async () => {
    try {
        const response = await axios.get(url + 'status');
        return response.data;
    } catch (error) {
        console.error("error fetching status", error);
    }
}

export const acceptProposed = async (info_id, access_token) => {
    const data = {
        "info_id": info_id,
        "status": "accepted"
    };
    try {
        const response = await axios.put(url + 'rw/user_info/status', data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data;
    }
    catch (error) {
        console.error("error accepting proposed", error);
    }
}

export const postTokenData = async (access_token, data) => {
    try {
        const response = await axios.post(url + 'plugin/google_token',
            data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        if (response.data.status === 201) {
            return response.data.message;
        }
    }
    catch (error) {
        console.error("error fetching postTokenData", error);
    }
}

export const getSections = async (access_token) => {
    try {
        const response = await axios.get(url + 'rw/sections',
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data;
    } catch (error) {
        console.error("error fetching sections", error);
    }
};

export const selectSection = async (data, access_token) => {
    try {
        const response = await axios.put(url + 'rw/sections', data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data;
    } catch (error) {
        console.error("error selecting section", error);
    }
};

export const updateUserInfo = async (data, access_token) => {
    try {
        const response = await axios.put(url + 'rw/user_info', data,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            })
        return response.data;
    } catch (error) {
        console.error("error updating user info", error);
    }
}

