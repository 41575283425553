import React, { useState, useEffect, useRef } from 'react';

const SearchBar = ({ searchTerm, setSearchTerm }) => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
      };

    return (
        <div className="flex items-center justify-center pt-4">
            <div className="relative w-1/2">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder="Search your friend's username"
                    className="w-full py-2 pl-4 pr-10 bg-[#252525] text-[#9E9E9E] rounded-full focus:outline-none focus:ring-2 focus:ring-secondary1 shadow-lg shadow-top-bottom"
                />
                <button className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#9E9E9E] hover:text-secondary1">
                    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.6989 15.75L11.6183 11.025C11.215 11.325 10.7513 11.5625 10.2271 11.7375C9.70293 11.9125 9.14513 12 8.55373 12C7.08868 12 5.8489 11.528 4.83438 10.584C3.81986 9.64 3.31234 8.487 3.3118 7.125C3.31126 5.763 3.81879 4.61 4.83438 3.666C5.84997 2.722 7.08976 2.25 8.55373 2.25C10.0177 2.25 11.2578 2.722 12.2739 3.666C13.29 4.61 13.7973 5.763 13.7957 7.125C13.7957 7.675 13.7016 8.19375 13.5134 8.68125C13.3252 9.16875 13.0699 9.6 12.7473 9.975L17.8279 14.7L16.6989 15.75ZM8.55373 10.5C9.5618 10.5 10.4188 10.172 11.1247 9.516C11.8306 8.86 12.1833 8.063 12.1828 7.125C12.1822 6.187 11.8295 5.39025 11.1247 4.73475C10.4199 4.07925 9.56287 3.751 8.55373 3.75C7.54459 3.749 6.68787 4.07725 5.98357 4.73475C5.27927 5.39225 4.92631 6.189 4.9247 7.125C4.92309 8.061 5.27605 8.858 5.98357 9.516C6.6911 10.174 7.54782 10.502 8.55373 10.5Z" fill="#E76A4E" />
                    </svg>
                </button>
                {loading && <div className="absolute mt-2 w-full text-center text-gray-500">Loading...</div>}
                {results.length > 0 && (
                    <ul className="absolute mt-2 w-full bg-[#252525] rounded-md shadow-lg">
                        {results.map((result, index) => (
                            <li key={index} className="p-2 border-b border-gray-700 text-[#9E9E9E] hover:bg-purple-600 hover:text-white">
                                {result.username}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default SearchBar;
