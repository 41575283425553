import React from 'react';

const InitialInput = ({ setInput, handleSend }) => {
    const handleSendHelper = async (e) => {
        setInput(e.target.value);
        await handleSend();
    }
    return (
        <div className='initial-input-container'>
            <div className="main-title">
                How Can I Help You Today?
            </div>
            <div className="screens-info">
                <div className="revealed-screen">
                    <div className="screen-title">
                        <img src={require('../images/revealed-logo.png')} alt="Revealed Logo" />
                        Revealed
                    </div>
                    <div className="screen-description">
                        Information processed from revealed messages are put in your revealed website.
                    </div>
                </div>
                <div className="bhai-screen">
                    <div className="screen-title">
                        <img src={require('../images/bhai-logo.png')} alt="Bhai Logo" />
                        Bhai
                    </div>
                    <div className="screen-description">
                        Information processed from bhai messages are put in your bhai website.
                    </div>
                </div>
            </div>

            <div className="try-search">
                <div className="try-search-text">
                    Try searching:
                </div>
                <div className="try-search-options">
                    <button onClick={handleSendHelper}>How to make a roti?</button>
                    <button onClick={handleSendHelper}>About TeraBhai</button>
                    <button onClick={handleSendHelper}>Yoga Day</button>
                    <button onClick={handleSendHelper}>About Meditations</button>
                </div>
            </div>
        </div>
    )
}

export default InitialInput;