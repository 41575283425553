import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { getStatus } from './service/api.jsx';
import Signup from './authPages/Signup.jsx';
import Login from './authPages/Login.jsx';
import OAuthCallback from './authPages/OAuthCallback.jsx';
import ProtectedRoutes from './ProtectedRoutes.js';
import Layout from './components/Layout';
import ResetPassword from './authPages/ResetPassword.jsx';

function App() {
  const [status, setStatus] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [maintainenceTimeStamp, setMaintainenceTimeStamp] = useState('');
  const [maintainenceDetail, setMaintainenceDetail] = useState('');
  useEffect(() => {
    const getStatusData = async () => {
      const response = await getStatus();
      // console.log(response);
      setStatus(response.status);
      setMaintainenceTimeStamp(response.timestamp);
      setMaintainenceDetail(response.detail);
    }
    getStatusData();
  }, []);
  if (status) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/callback' Component={OAuthCallback} />
          <Route
            path="/signup"
            element={
              <Signup
                setIsAuthenticated={setIsAuthenticated}
              />
            }
          />
          <Route
            path="/login"
            element={
              <Login
                setIsAuthenticated={setIsAuthenticated}
              />
            }
          />
          <Route
            path="/resetpassword"
            element={
              <ResetPassword
              />
            }
          />
          <Route
            element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}
          >
            <Route
              exact
              path="/layout"
              element={<Layout />}
            />
          </Route>
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
    );
  }
  else {
    return (
      <div className='server-down'>
        <div className='server-down-text'>Server is Down</div>
        <div className='server-down-detail'>{maintainenceDetail}</div>
        <div className='server-down-timestamp'>Try again at {maintainenceTimeStamp}</div>
      </div>
    )
  }

}
export default App;