import React, { useState } from 'react'
import { RiCloseLine } from 'react-icons/ri';

const SideBarProfile = (
    {
        username,
        email,
        profilePic,
        newUsername,
        setNewUsername,
        newPassword,
        setNewPassword,
        newEmail,
        setNewEmail,
        newProfilePic,
        setNewProfilePic,
        isModalOpen,
        setIsModalOpen,
        handleProfileUpdate,
    }) => {

    return (
        <>
            <div className="sidebar-profile" onClick={() => setIsModalOpen(true)}>
                <div className="sidebar-profile-pic">
                    {profilePic &&
                        <img src={profilePic} alt="Profile Pic" />
                    }
                </div>
                <div className="sidebar-profile-details">
                    <div className="sidebar-profile-username">{username}</div>
                    <div className="sidebar-profile-email">{email}</div>
                </div>
            </div>
            {
                isModalOpen && (
                    <>
                        <div className="modal-outside-container" onClick={() => setIsModalOpen(false)} />
                        <div className="modal-container sidebar-profile-modal-container">
                            <div className="modal-header">
                                <div className="modal-title">Profile</div>
                                <button className="modal-close-icon" onClick={() => setIsModalOpen(false)}>
                                    <RiCloseLine />
                                </button>
                            </div>
                            <div className="sidebar-profile-modal">
                                <div className="profile-modal-profile-pic">
                                    <img src={profilePic} alt="Profile Pic" />
                                </div>
                                <div className="profile-modal-form">
                                    <form onSubmit={handleProfileUpdate}>
                                        <div className="profile-usename-update">
                                            <label htmlFor="username">Change username:</label>
                                            <input type="text" id='username' value={newUsername} onChange={(e) => setNewUsername(e.target.value)}
                                                placeholder='Enter new username' />
                                        </div>
                                        <div className="profile-password-update">
                                            <label htmlFor="password">Change password:</label>
                                            <input type="password" id='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder='Enter new password' />
                                        </div>
                                        <div className="profile-email-update">
                                            <label htmlFor="email">Change email:</label>
                                            <input type="text" id='email' value={newEmail} onChange={(e) => setNewEmail(e.target.value)} placeholder='Enter new email' />
                                        </div>
                                        <div className="profile-pic-update">
                                            <label htmlFor="image">Change profile pic:</label>
                                            <input
                                                type="file" id='image'
                                                accept='image/*'
                                                onChange={(e) => setNewProfilePic(e.target.files[0])} />
                                        </div>
                                        <div className="profile-modal-buttons">
                                            <button
                                                onClick={(e) => handleProfileUpdate(e)}
                                                type='submit'
                                            >
                                                Save
                                            </button>
                                            <button
                                                onClick={() => setIsModalOpen(false)}>
                                                Close
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default SideBarProfile