import React, { useState,useEffect,useRef  } from 'react';
import { getProposed,acceptProposed,getSections,selectSection } from '../service/api';

const Modal = ({toggleOpen,selectedProposed,setSelectedProposed}) => {
  // const [selectedProposed, setSelectedProposed] = useState(Proposed);
  const [sections, setSections] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [modifiedSections, setModifiedSections] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [editingText, setEditingText] = useState("");


  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        toggleOpen();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleOpen]);
  // const sections = Array(6).fill('About');
  const getSectionData = async (access_token) => {
    try{
      const response = await getSections(access_token);
      console.log(response);
      if(selectedSections.length===0){
        const a=[];
      response.forEach(element => {
        if(element.user_selection===true){
          a.push(element);
        }
      });
      setSelectedSections([...a])
      setModifiedSections(true);
    }
      console.log("selectedSections in getSelectionData",selectedSections);
      setSections(response);
    }catch(error){
      console.error('Error fetching section data:', error);
    }
  }
  const selectSectionFunction = async () => {
    const data={
      "section_ids":selectedSections.map((section) => section.section_id)
    }
    const response = await selectSection(data,localStorage.getItem('access_token'));
    console.log("selectSectionFunction",response);
  }
  useEffect(() => {
    getSectionData(localStorage.getItem('access_token'));
  },[]);

  const acceptProposedData = async (access_token,proposed) => {
    try {
      const response = await acceptProposed(proposed.user_info.info_id,access_token);
      console.log(response);
    } catch (error) {
      console.error('Error accepting proposed data:', error);
    }
  }
  const handleAccept = (proposed) =>{
    acceptProposedData(localStorage.getItem('access_token'),proposed);
  }
  const handleReject = (proposed) => {
    const updatedProposed = selectedProposed.filter(item => item !== proposed);
    setSelectedProposed(updatedProposed);
  }
  const handleEdit = (proposed) => {
    setEditingItem(proposed);
    setEditingText(proposed.user_info.info);
  }
  const handleSaveEdit = () => {
    const updatedProposed = selectedProposed.map(item =>
      item.user_info.info_id === editingItem.user_info.info_id
        ? { ...item, user_info: { ...item.user_info, info: editingText } }
        : item
    );
    setSelectedProposed(updatedProposed);
    setEditingItem(null);
    setEditingText("");
    console.log("selectedProposed in edit",selectedProposed);
  };
  const handleSectionSelect = (section) => {
    console.log("selectedSections on enter",selectedSections);
    if (selectedSections.includes(section)) {
      console.log("selectedSections before",selectedSections);
      setSelectedSections(selectedSections.filter(item => item.section_id !== section.section_id));
      console.log("selectedSections after",selectedSections);
    } else if (selectedSections.length < 5 ) {
      setSelectedSections([...selectedSections, section]);
    } else {
      alert("You can select only 5 sections");
      console.log("selectedSections",selectedSections);
    }
    console.log("selectedSections outside",selectedSections);
  }
  const handleSectionSubmit = () => {
    selectSectionFunction();
    alert("Sections Selected");
  }
  // const handleClear = () => {
  //   setSelectedSections([]);
  // }
  

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-end pr-4 pt-4 m-0 " style={{ marginLeft: '0px' }}>
    <div className="absolute inset-0 bg-black w-full  opacity-60 "></div>
    <div ref={modalRef} className="relative bg-newBackground text-white rounded-lg shadow-lg p-6 w-96 h-90 mt-4 -translate-x-80">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold font-helvetica" >reveal consent</h2>
        <button onClick={toggleOpen}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill="#E76A4E" />
            <path d="M8.625 7.78125C8.40122 7.78125 8.18661 7.87015 8.02838 8.02838C7.87015 8.18661 7.78125 8.40122 7.78125 8.625V15.375C7.78125 15.5988 7.87015 15.8134 8.02838 15.9716C8.18661 16.1299 8.40122 16.2188 8.625 16.2188H15.375C15.5988 16.2188 15.8134 16.1299 15.9716 15.9716C16.1299 15.8134 16.2188 15.5988 16.2188 15.375V14.9531C16.2188 14.6175 16.3521 14.2955 16.5894 14.0582C16.8268 13.8208 17.1487 13.6875 17.4844 13.6875C17.82 13.6875 18.142 13.8208 18.3793 14.0582C18.6167 14.2955 18.75 14.6175 18.75 14.9531V15.375C18.75 16.2701 18.3944 17.1285 17.7615 17.7615C17.1285 18.3944 16.2701 18.75 15.375 18.75H8.625C7.72989 18.75 6.87145 18.3944 6.23851 17.7615C5.60558 17.1285 5.25 16.2701 5.25 15.375V8.625C5.25 7.72989 5.60558 6.87145 6.23851 6.23851C6.87145 5.60558 7.72989 5.25 8.625 5.25H9.04688C9.38254 5.25 9.70446 5.38334 9.94181 5.62069C10.1792 5.85804 10.3125 6.17996 10.3125 6.51562C10.3125 6.85129 10.1792 7.17321 9.94181 7.41056C9.70446 7.64791 9.38254 7.78125 9.04688 7.78125H8.625ZM13.2656 7.78125C12.93 7.78125 12.608 7.64791 12.3707 7.41056C12.1333 7.17321 12 6.85129 12 6.51562C12 6.17996 12.1333 5.85804 12.3707 5.62069C12.608 5.38334 12.93 5.25 13.2656 5.25H17.4844C17.82 5.25 18.142 5.38334 18.3793 5.62069C18.6167 5.85804 18.75 6.17996 18.75 6.51562V10.7344C18.75 11.07 18.6167 11.392 18.3793 11.6293C18.142 11.8667 17.82 12 17.4844 12C17.1487 12 16.8268 11.8667 16.5894 11.6293C16.3521 11.392 16.2188 11.07 16.2188 10.7344V9.57L14.16 11.6288C13.9201 11.8523 13.6028 11.974 13.2749 11.9682C12.947 11.9624 12.6341 11.8296 12.4023 11.5977C12.1704 11.3659 12.0376 11.053 12.0318 10.7251C12.026 10.3972 12.1477 10.0799 12.3712 9.84L14.43 7.78125H13.2656Z" fill="#F5F5F5" />
          </svg>
        </button>
      </div>
      <div className="space-y-20">
        <div className='space-y-2'>
          {(selectedProposed.length>0)?selectedProposed.map((proposed) => (
            <div key={proposed.user_info.info_id} className="flex items-start justify-between">
              <div>
                <span className='bg-white rounded-full text-xs px-1 py-0.5 text-black font-helvetica'>{proposed.user_info.section_name}</span>
              </div>
            <div className="flex-1 mx-1 ">
                {editingItem?.user_info.info_id === proposed.user_info.info_id ? (
                  <input
                    className="text-sm font-helvetica items-start content-start text-white bg-newBackground focus:outline-none focus:ring-1 p-1  focus:ring-secondary1 rounded-full"
                    value={editingText}
                    onChange={(e) => setEditingText(e.target.value)}
                  />
                ) : (
                  <span className='text-sm font-helvetica items-start content-start'>{proposed.user_info.info}</span>
                )}
            </div>
            <div className="flex space-x-2 ">
                {editingItem?.user_info.info_id === proposed.user_info.info_id ? (
                  <button onClick={handleSaveEdit} className="text-black bg-white rounded-full text-xs px-2 py-0.5">Save</button>
                ) : (
                  <>
                    <svg onClick={() => handleAccept(proposed)} className='cursor-pointer' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.03125C0 5.16645 0.74079 3.37802 2.05941 2.05941C3.37802 0.74079 5.16645 0 7.03125 0C8.89605 0 10.6845 0.74079 12.0031 2.05941C13.3217 3.37802 14.0625 5.16645 14.0625 7.03125C14.0625 8.89605 13.3217 10.6845 12.0031 12.0031C10.6845 13.3217 8.89605 14.0625 7.03125 14.0625C5.16645 14.0625 3.37802 13.3217 2.05941 12.0031C0.74079 10.6845 0 8.89605 0 7.03125ZM6.63 10.0406L10.6781 4.98L9.94687 4.395L6.495 8.70844L4.05 6.67125L3.45 7.39125L6.63 10.0406Z" fill="#F5F5F5" />
                    </svg>
                    <svg onClick={() => handleReject(proposed)} className='cursor-pointer' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.7811 14.0625C11.6209 14.0625 14.8125 10.8778 14.8125 7.03125C14.8125 3.1915 11.6139 0 7.77404 0C3.92775 0 0.75 3.1915 0.75 7.03125C0.75 10.8778 3.93451 14.0625 7.7811 14.0625ZM5.30661 10.0919C5.22965 10.0919 5.15343 10.0768 5.08231 10.0474C5.01119 10.018 4.94657 9.97486 4.89215 9.92044C4.83773 9.86602 4.79457 9.8014 4.76515 9.73028C4.73574 9.65917 4.72064 9.58294 4.72071 9.50598C4.72071 9.34745 4.78954 9.20951 4.89984 9.10598L6.95402 7.03831L4.89984 4.97005C4.84319 4.91999 4.79788 4.8584 4.76694 4.78942C4.736 4.72044 4.72014 4.64565 4.72042 4.57005C4.72042 4.25328 4.98249 3.99798 5.30661 3.99798C5.46515 3.99798 5.60309 4.06004 5.70663 4.17034L7.77434 6.23154L9.85646 4.16357C9.97353 4.03945 10.0976 3.98416 10.2494 3.98416C10.5732 3.98416 10.8353 4.24622 10.8353 4.56358C10.8353 4.72181 10.78 4.85299 10.663 4.96329L8.59495 7.03802L10.6559 9.09245C10.7594 9.20274 10.8283 9.34069 10.8283 9.50598C10.8283 9.58294 10.8132 9.65917 10.7838 9.73028C10.7544 9.8014 10.7112 9.86602 10.6568 9.92044C10.6024 9.97486 10.5378 10.018 10.4667 10.0474C10.3955 10.0768 10.3193 10.0919 10.2424 10.0919C10.1665 10.0919 10.0914 10.0766 10.0215 10.047C9.9516 10.0174 9.88838 9.97403 9.83558 9.91952L7.77463 7.85155L5.72016 9.91952C5.66655 9.97482 5.60223 10.0186 5.53113 10.0483C5.46003 10.0779 5.38364 10.0927 5.30661 10.0919Z" fill="#F5F5F5" />
                    </svg>
                    <svg onClick={() => handleEdit(proposed)} className='cursor-pointer' width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="8.12476" cy="7.5" r="7.5" fill="#F5F5F5" />
                      <path d="M5.50024 10.125H6.03462L9.70024 6.45936L9.16587 5.92498L5.50024 9.59061V10.125ZM4.75024 10.875V9.28123L9.70024 4.34061C9.77524 4.27186 9.85812 4.21873 9.94887 4.18123C10.0396 4.14373 10.137 4.125 10.241 4.125C10.345 4.125 10.4424 4.14373 10.5331 4.18123C10.6239 4.21873 10.7067 4.27186 10.7817 4.34061L11.559 5.11786C11.6277 5.19286 11.6827 5.27567 11.724 5.36642C11.7652 5.45717 11.7855 5.55342 11.7855 5.65523C11.7855 5.75705 11.7652 5.85329 11.724 5.94405C11.6827 6.0348 11.6277 6.11761 11.559 6.19261L6.60837 11.125H4.75024Z" fill="#8B8A8A" />
                    </svg>
                  </>
                )}
              </div>
            </div>
          )) : <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className='text-sm font-helvetica'>No proposed statements</span>
              </div>
            </div>}
        </div>
        <div className="relative">
          <button className=" bg-secondary1 px-4 py-2 rounded-full flex flex-row align-middle">
            <span className='font-helvetica font-semibold'>Sections</span>
              <svg className='font-semibold m-2 mt-2.5' width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.67969 0.00390625L7.5 0.824219L4 4.32422L0.5 0.824219L1.32031 0.00390625L4 2.68359L6.67969 0.00390625Z" fill="#F2F2F2" />
              </svg>
              
          </button>
          <div className="pl-2 pt-2 font-helvetica font-bold">
            <span>Select any five!!</span>
              </div>
          <div className="relative ">
          
            {/* <div className="grid grid-cols-2 grid-rows-2 pt-4 space-y-2 space-x-2 h-64 w-64 overflow-auto">
              {sections.map((section) => (
                <div onClick={()=>handleSectionSelect(section)} className={` mt-2 ml-2 py-2 px-2 rounded-full border-dashed border-2 border-[#403f3f] ${selectedSections.includes(section) ? 'bg-[#403f3f]' : ''}`}>
                  <button >{section.section_name}</button>
                </div>
              ))}
            </div> */}
              <div className="max-h-40 overflow-y-auto space-y-2 grid grid-cols-2 space-x-2 p-2">
                {(sections.length>0)?sections.map((section) => (
                  <button
                    key={section.section_id}
                    className={`mt-2 ml-2 w-50 p-2 rounded-full border-dotted border-2 border-gray-300 ${selectedSections.includes(section) ? 'bg-[#403f3f]' : ''
                      } text-white `}
                    onClick={() => handleSectionSelect(section)}
                  >
                    {section.section_name}
                  </button>
                )):<div className="flex items-center justify-between">
                <div className="flex items-center">
                <span className='text-sm font-helvetica text-secondary1'>Loading...</span>
                </div>
                </div>}
              </div>
              <div className='flex flex-row-reverse'>
              <button onClick={handleSectionSubmit} className=" bg-secondary1 px-4 py-2 mt-2 rounded-full  align-middle">
                <span className='font-helvetica font-semibold '>Select Sections</span>
              </button>
              {/* <button onClick={handleClear} className=" bg-secondary1 px-4 py-2 mt-2 rounded-full  align-middle">
                <span className='font-helvetica font-semibold '>Clear Sections</span>
              </button> */}
              </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
);
  
};

export default Modal;



//test data
// {user_info:{
//   "info_id": 4,
//   "section_name": "Socials",
//   "info": "hello world"
//     }},
// {user_info:{
// "info_id": 5,
// "section_name": "Education",
// "info": "hello name hello name hello name hello name hello name "
//     }}