import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import OTPModal from '../authPages/OTPModal';
import SideBarProfile from './SideBarProfile';
import SideBarPlugins from './SideBarPlugins';
import SideBarExpertise from './SideBarExpertise';
import SideBarFooter from './SideBarFooter';
import { check_token, getAuthURL } from '../service/api';
import { REDIRECT_URI } from '../constants';
import axios from 'axios';

const SideBar2 = () => {
    const navigate = useNavigate();
    // SideBar Profile
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [profilePic, setProfilePic] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newProfilePic, setNewProfilePic] = useState('');
    const [otp, setOtp] = useState('');
    const [showOTPModal, setShowOTPModal] = useState(false);

    // SideBar Plugins
    const [plugins, setPlugins] = useState([]);
    const access_token = localStorage.getItem('access_token');
    const [isLawChecked, setIsLawChecked] = useState(false);
    const [isHealthChecked, setIsHealthChecked] = useState(false);
    const [isFinanceChecked, setIsFinanceChecked] = useState(false);
    const [isStocksChecked, setIsStocksChecked] = useState(false);
    const [hoveredPlugin, setHoveredPlugin] = useState(null);
    const [clickedPlugin, setClickedPlugin] = useState(null);
    const [acceptedPersonalizationsData, setAcceptedPersonalizationsData] = useState([]);

    // SideBar Footer
    const [isDataModalOpen, setIsDataModalOpen] = useState(false);
    const [isHelpCenterOpen, setIsHelpCenterOpen] = useState(false);
    const [persistedData, setPersistedData] = useState(null);
    const [revealedData, setRevealedData] = useState(null);
    const [proposedPersonalizationsData, setProposedPersonalizationsData] = useState([]);
    const [revealedWebsiteData, setRevealedWebsiteData] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [helpOption, setHelpOption] = useState('');
    const [helpData, setHelpData] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editContent, setEditContent] = useState("");
    const [currentID, setCurrentID] = useState();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [currentPlugginDropdown, setCurrentPlugginDropdown] = useState('');
    const [initialFetchComplete, setInitialFetchComplete] = useState(false);

    useEffect(() => {
        if (isDataModalOpen) {
            fetchPersistedData();
            fetchRevealedData();
            fetchRevealedWebsiteData();
            fetchPersonalizationData();
        }
    }, [isDataModalOpen]);

    useEffect(() => {
        const fetchData = async () => {
            await getUserDetails();
            const bearerToken = localStorage.getItem("access_token");

            try {
                const pluginsResponse = await axios.get('https://production.terabh.com/plugin/description', {
                    headers: { 'Authorization': `Bearer ${bearerToken}` }
                });

                const parsedPlugins = Object.keys(pluginsResponse.data).map(key => ({
                    name: key,
                    desc: pluginsResponse.data[key].data,
                    image: pluginsResponse.data[key].link,
                    checked: false
                }));
                setPlugins(parsedPlugins);

                const enabledPluginsResponse = await axios.get('https://production.terabh.com/plugin/get_enabled', {
                    headers: { 'Authorization': `Bearer ${bearerToken}` }
                });

                const updatedPlugins = parsedPlugins.map(plugin => ({
                    ...plugin,
                    checked: enabledPluginsResponse.data[plugin.name]
                }));
                setPlugins(updatedPlugins);

                const expertiseResponse = await axios.get('https://production.terabh.com/expertise/get', {
                    headers: { 'Authorization': `Bearer ${bearerToken}` }
                });

                if (expertiseResponse.data.law)
                    setIsLawChecked(expertiseResponse.data.law);
                else setIsLawChecked(null);
                if (expertiseResponse.data.health)
                    setIsHealthChecked(expertiseResponse.data.health);
                else setIsHealthChecked(null);
                if (expertiseResponse.data.finance)
                    setIsFinanceChecked(expertiseResponse.data.finance);
                else setIsFinanceChecked(null);
                if (expertiseResponse.data.stocks)
                    setIsStocksChecked(expertiseResponse.data.stocks);
                else setIsStocksChecked(null);

            } catch (error) {
                console.error('Error fetching initial data:', error);
            } finally {
                setInitialFetchComplete(true);  // Set to true after initial fetch is done
            }
        };

        fetchData();
    }, []);

    const handleAuth = async (service) => {
        const authUrl = await getAuthURL(service, REDIRECT_URI, access_token);
        // console.log("this is authUrl", authUrl);
        const encodedauthUrl = decodeURIComponent(authUrl.url);
        window.location.href = encodedauthUrl
    }

    const getUserDetails = async () => {
        const bearerToken = localStorage.getItem("access_token");
        try {
            const response = await axios.get('https://production.terabh.com/profile/get',
                {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`
                    }
                })
            // console.log(response);
            if (response.status !== 200) {
                alert('Error fetching user details');
                return;
            }
            setUsername(response.data.username);
            setEmail(response.data.email);
            await fetchProfilePic(response.data.username);
        } catch (error) {
            console.log("Error fetching user details", error);
        }
    }

    const fetchProfilePic = async (username) => {
        const bearerToken = localStorage.getItem("access_token");
        const response = await fetch(`https://production.terabh.com/profile/get_profile_pic?username=${username}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${bearerToken}`,
                'Cache-Control': 'no-cache'
            },
            cache: 'no-store'
        });
        console.log(response);
        if (!response.ok) {
            alert('Failed to fetch profile picture');
        }
        const blob = await response.blob();
        // Create an object URL from the Blob
        const imageUrl = URL.createObjectURL(blob);
        setProfilePic(imageUrl);
        console.log("This is the imageUrl", imageUrl);
    };

    const fetchPersonalizationData = async () => {
        try {
            const bearerToken = localStorage.getItem("access_token");
            const response = await axios.get('https://production.terabh.com/data/personalization', {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`
                }
            });
            setProposedPersonalizationsData(response.data);
            console.log("personalisation data", response.data);
        } catch (error) {
            console.error('Error fetching proposed personalizations:', error);
        }
    };

    // SideBarProfile functions
    const handleVerifyOTP = async () => {
        const otpNum = Number(otp);
        if (otpNum < 1000 || otpNum > 9999) {
            alert('Enter valid OTP bhai!');
            return;
        }
        try {
            const response = await fetch(`https://production.terabh.com/profile/verify_otp?otp=${otpNum}&newEmail=${newEmail}`, {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("access_token")}`
                }
            })
            if (!response.ok) {
                alert('Error changing email address!')
                return;
            }
            alert('Email address changed successfully!');
            setEmail(newEmail);
            setNewEmail('');
            setOtp('');
            setShowOTPModal(false);
        }
        catch (err) {
            alert('Error verifying OTP: ', err.message);
        }
    }
    const handleFormSubmitUserName = async (e) => {
        e.preventDefault();
        const bearerToken = localStorage.getItem("access_token");
        const response = await fetch(`https://production.terabh.com/profile/update?new_username=${newUsername}`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${bearerToken}`
            }
        }).then(response => response.json()).then(response => {
            let bearer = response.tokens.access_token;
            console.log("This is the response token after changing username", response.tokens.access_token);
            localStorage.setItem('access_token', bearer);
            setUsername(newUsername);
            getUserDetails();
        });
        // localStorage.setItem("access_token",response.data.tokens.access_tokens);
        setNewUsername('');
        return response;
    }

    const handleFormSubmitPassword = async (e) => {
        e.preventDefault();
        const bearerToken = localStorage.getItem("access_token");
        const response = await fetch(`https://production.terabh.com/profile/update?new_password=${newPassword}`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${bearerToken}`
            }
        }).then(response => response.json()).then(response => {
            let bearer = response.tokens.access_token;
            console.log("This is the response token after changing password", response.tokens.access_token)
            localStorage.setItem('access_token', bearer);
            setNewPassword('');
        });
        return response;
    }

    const handleFormSubmitEmail = async (e) => {
        e.preventDefault();
        const bearerToken = localStorage.getItem("access_token");
        const response = await fetch(`https://production.terabh.com/profile/update?new_email=${newEmail}`, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${bearerToken}`
            }
        })
        if (response.status === 200) alert("OTP sent to your new-mail ID");
        return response;
    }

    const handleFormSubmitProfilePic = () => {
    }

    const handleFormVerifyOTP = async (e) => {
        e.preventDefault();
        const bearerToken = localStorage.getItem("access_token");
        const response = await fetch(`https://production.terabh.com/profile/verify_otp?otp=${otp}&email=${newEmail}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${bearerToken}`
            }
        })
        if (response.status === 200) alert("OTP verified and email change successful");
        return response;
    }

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        if (newUsername === '' && newPassword === '' && newEmail === '' && newProfilePic === '') {
            return;
        }
        if (newEmail !== '') {
            setShowOTPModal(true);
        }
        try {
            const response = await fetch(`https://production.terabh.com/profile/update?new_username=${newUsername}&new_password=${newPassword}&new_email=${newEmail}`, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("access_token")}`
                }
            })
            if (!response.ok) {
                alert('Error updating profile!')
                return;
            }
            alert('Profile updated successfully!');
            setUsername(newUsername !== '' ? newUsername : username);
            setPassword(newPassword !== '' ? newPassword : password);
            setProfilePic(newProfilePic !== '' ? newProfilePic : profilePic);
        }
        catch (err) {
            alert('Error updating profile: ', err.message);
        }
    }

    // SideBarPlugins functions
    const handlePluginCheckBoxChange = async (index) => {
        const updatedPlugins = [...plugins];
        const service = updatedPlugins[index].name;
        if (updatedPlugins[index].checked === false) {
            const response = await check_token(service, localStorage.getItem("access_token"));
            // console.log("this is response", response);
            if (!response) {
                if (updatedPlugins[index].name === "gmail") {
                    handleAuth('gmail');
                }
                if (updatedPlugins[index].name === "calendar") {
                    handleAuth('calendar');
                }
                if (localStorage.getItem(`${service}_consent`) === 'true') {
                    updatedPlugins[index].checked = !updatedPlugins[index].checked;
                } else {
                    alert("Please give consent to use this plugin");
                }
            }
            else {
                updatedPlugins[index].checked = !updatedPlugins[index].checked;
            }
        }
        else {
            updatedPlugins[index].checked = !updatedPlugins[index].checked;
        }
        setPlugins(updatedPlugins);
        const plugin = updatedPlugins[index];
        const enabled_state = plugin.checked;
        const bearerToken = localStorage.getItem("access_token");
        axios.put(`https://production.terabh.com/plugin/change_state`, null, {
            params: {
                plugin: service,
                enabled_state: enabled_state
            },
            headers: {
                'Authorization': `Bearer ${bearerToken}`
            }
        })
            .then(response => {
                console.log('Plugin state updated successfully:', response.data);
            })
            .catch(error => {
                console.error('Error updating plugin state:', error);
            });
    }

    const handleToggleDescription = (index) => {
        setClickedPlugin(clickedPlugin === index ? null : index);
    };

    // SideBarExpertise functions
    const handleExpertiseCheckBoxChange = async (expertise, isChecked) => {
        if (!initialFetchComplete) return;  // Prevent calling during initial state setting

        const updatedState = !isChecked;
        const bearerToken = localStorage.getItem("access_token");

        try {
            const response = await axios.put(`https://production.terabh.com/expertise/update/?expertise=${expertise}&enabled_state=${updatedState}`, null, {
                headers: { 'Authorization': `Bearer ${bearerToken}` }
            });
            console.log('Expertise state updated successfully:', response.data);

            // Update the local state based on expertise type
            switch (expertise) {
                case 'law':
                    setIsLawChecked(updatedState);
                    break;
                case 'health':
                    setIsHealthChecked(updatedState);
                    break;
                case 'finance':
                    setIsFinanceChecked(updatedState);
                    break;
                case 'stocks':
                    setIsStocksChecked(updatedState);
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error('Error updating expertise state:', err.message);
        }
    };

    // SideBarFooter functions
    const handleSectionClick = (section) => {
        setSelectedSection(selectedSection === section ? null : section);
    };

    const fetchPersistedData = async () => {
        try {
            const bearerToken = localStorage.getItem("access_token");
            const response = await axios.get('https://production.terabh.com/data/persisted', {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`
                }
            });
            setPersistedData(response.data);
        } catch (error) {
            console.error('Error fetching persisted data:', error);
        }
    };

    const fetchRevealedData = async () => {
        try {
            const bearerToken = localStorage.getItem("access_token");
            const response = await axios.get('https://production.terabh.com/data/revealed', {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`
                }
            });
            setRevealedData(response.data);
        } catch (error) {
            console.error('Error fetching revealed data:', error);
        }
    };

    const fetchRevealedWebsiteData = async () => {
        try {
            const bearerToken = localStorage.getItem("access_token");
            const response = await axios.get(`https://production.terabh.com/data/rw/accepted/${username}`, {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`
                }
            });
            if (response.data.message === "User-sections not found") {
                setRevealedWebsiteData([]);
            }
            else {
                setRevealedWebsiteData(response.data);
            }
        } catch (error) {
            console.error('Error fetching revealed-website data:', error);
        }
    }

    const handleToggleDataModal = () => {
        setIsDataModalOpen((prev) => !prev);
    }

    const handleToggleHelpCenter = () => {
        setIsHelpCenterOpen((prev) => !prev);
        setHelpOption('')
        setHelpData('')
    }

    const handleHelpOption = async (option) => {
        if (helpOption === option) {
            setHelpOption('')
            setHelpData('')
        }
        else {
            setHelpOption(option)
            setHelpData('')
            let endpointURL = '';
            switch (option) {
                case 'faqs':
                    endpointURL = 'https://production.terabh.com/cc/FAQs';
                    break;
                case 'contactUs':
                    endpointURL = 'https://production.terabh.com/cc/contact_us';
                    break;
                default:
                    break;
            }
            await fetchDataFromEndPoint(endpointURL);
        }
    }
    const fetchDataFromEndPoint = async (endpointURL) => {
        try {
            const response = await fetch(endpointURL);
            const responseData = await response.json();
            // console.log(responseData);
            setHelpData(responseData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleLogoutButtonClick = () => {
        localStorage.clear();
        navigate('/login');
    }
    return (
        <div className="layout-sidebar">
            <div className="sidebar">
                <SideBarProfile
                    username={username}
                    email={email}
                    profilePic={profilePic}
                    newUsername={newUsername}
                    setNewUsername={setNewUsername}
                    newPassword={newPassword}
                    setNewPassword={setNewPassword}
                    newEmail={newEmail}
                    setNewEmail={setNewEmail}
                    newProfilePic={newProfilePic}
                    setNewProfilePic={setNewProfilePic}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    handleProfileUpdate={handleProfileUpdate}
                    handleFormSubmitProfilePic={handleFormSubmitProfilePic}
                />
                <SideBarPlugins
                    plugins={plugins}
                    clickedPlugin={clickedPlugin}
                    handlePluginCheckBoxChange={handlePluginCheckBoxChange}
                    handleToggleDescription={handleToggleDescription}
                />
                <SideBarExpertise
                    isLawChecked={isLawChecked}
                    isHealthChecked={isHealthChecked}
                    isFinanceChecked={isFinanceChecked}
                    isStocksChecked={isStocksChecked}
                    handleExpertiseCheckBoxChange={handleExpertiseCheckBoxChange}
                />
                <SideBarFooter
                    isDataModalOpen={isDataModalOpen}
                    isHelpCenterOpen={isHelpCenterOpen}
                    helpOption={helpOption}
                    helpData={helpData}
                    selectedSection={selectedSection}
                    persistedData={persistedData}
                    revealedData={revealedData}
                    proposedPersonalizationsData={proposedPersonalizationsData}
                    revealedWebsiteData={revealedWebsiteData}
                    handleSectionClick={handleSectionClick}
                    handleToggleDataModal={handleToggleDataModal}
                    handleToggleHelpCenter={handleToggleHelpCenter}
                    handleHelpOption={handleHelpOption}
                    handleLogoutButtonClick={handleLogoutButtonClick}
                />
            </div>
            {showOTPModal && (
                <OTPModal
                    otp={otp}
                    setOtp={setOtp}
                    setShowOTPModal={setShowOTPModal}
                    handleVerifyOTP={handleVerifyOTP}
                />
            )}
        </div>
    )
}

export default SideBar2