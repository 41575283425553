import React, { useState, useEffect } from 'react'
import { ChangeMessageState } from '../../service/api';

const Filter = ({ message, setbhaiConversation }) => {

  // Ensure that if all states are false, active is set to true by default
  useEffect(() => {
    if (!message.active && !message.persist && !message.reveal) {
      message.active = true;
    }
  })
  const [isActive, setIsActive] = useState(message.active);
  const [isPersist, setIsPersist] = useState(message.persist);
  const [isReveal, setIsReveal] = useState(message.reveal);

  const handleUpdateFilter = async (filter) => {
    const currValue = filter === "active" ? isActive : filter === "persist" ? isPersist : isReveal;
    await ChangeMessageState({
      message_id: message.message_id,
      state: filter,
      value: !currValue,
    }, localStorage.getItem('access_token'));

    setbhaiConversation((prevConversation) => {
      return prevConversation.map((eachMessage) => {
        if (eachMessage.message_id === message.message_id) {
          if (filter === "active") {
            return { ...eachMessage, active: !currValue };
          }
          else if (filter === "persist") {
            return { ...eachMessage, persist: !currValue };
          }
          else {
            return { ...eachMessage, reveal: !currValue };
          }
        }
        return eachMessage;
      });
    });
    if (filter === "active") {
      setIsActive(!currValue);
    }
    else if (filter === "persist") {
      setIsPersist(!currValue);
    }
    else {
      setIsReveal(!currValue);
    }
    console.log(filter + ': ' + !currValue);
  };
  return (
    <>
      <button className="filter" onClick={() => handleUpdateFilter("active")}>
        {isActive ? (
          <img src={require('../../images/active-1.png')} alt="active-1" />
        ) : (
          <img src={require('../../images/active-2.png')} alt="active-2" />
        )}
      </button>
      <button className="filter" onClick={() => handleUpdateFilter("persist")}>
        {isPersist ? (
          <img src={require('../../images/persist-1.png')} alt="persist-1" />
        ) : (
          <img src={require('../../images/persist-2.png')} alt="persist-2" />
        )}
      </button>
      <button className="filter" onClick={() => handleUpdateFilter("reveal")}>
        {isReveal ? (
          <img src={require('../../images/reveal-1.png')} alt="reveal-1" />
        ) : (
          <img src={require('../../images/reveal-2.png')} alt="reveal-2" />
        )}
      </button>
    </>
  )
}

export default Filter