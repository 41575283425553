import React, { useState } from 'react';
import Navbar from './Navbar';
import SideBar2 from './SideBar2';
import Bhai from './Bhai'
import RevealedScreen from './RevealedScreen';

const Layout = () => {
    const [mode, setMode] = useState('bhai');

    return (
        <div className="layout-container">
            <Navbar setMode={setMode} />
            <div className="layout">
                <SideBar2 />
                <div className='layout-screen'>
                    {(mode === 'revealed') ? <RevealedScreen /> : <Bhai />}
                </div>
            </div>
        </div>
    );
};

export default Layout;
