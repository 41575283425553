import React, { useState, useRef } from 'react'
import { getReply, regenerateResponse, likeMessage, deleteMessage } from '../../service/api';
import { FaImage } from "react-icons/fa6";
import { FaFilePdf, FaRegThumbsDown, FaRegThumbsUp, FaThumbsDown, FaThumbsUp, FaSyncAlt } from "react-icons/fa";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { MdDelete, MdEdit, MdAudiotrack } from "react-icons/md";
import { FaRegCirclePlay, FaRegCirclePause } from 'react-icons/fa6';
import Filter from './Filter';

const BhaiMessageRenderer = ({ message, profileImage, setbhaiConversation, handleDownload, isLastMessage }) => {

    // const [isReveal, setIsReveal] = useState(message.reveal); for switch button!
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [likeStatus, setLikeStatus] = useState(message.liked_status);
    const [isLiked, setIsLiked] = useState(false);
    const [isDisliked, setIsDisliked] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [editableContent, setEditableContent] = useState(message.content);
    const textInputRef = useRef(null);
    const [editInput, setEditInput] = useState('');



    const handleEditedSend = async () => {
        if (!editableContent) return;
        setIsEditing(false);
        setIsLoading(true);
        const response = await getReply({ message: editInput }, localStorage.getItem('access_token'));
        console.log(response);
        setIsLoading(false);
        // edit_text has not been implemented
        // bhaiconversation needs to be updated too
    }

    const handleRegenerate = async () => {
        setIsLoading(true);
        const response = await regenerateResponse(localStorage.getItem('access_token'));
        console.log(response);
        setbhaiConversation((prevConversation) =>
            prevConversation.map((eachMessage) =>
                eachMessage.message_id === message.message_id ? response : eachMessage
            )
        );
        setIsLoading(false);
    };

    const handleEdit = async () => {
        setIsEditing(true);
        setEditInput(message.content);
    };

    const handleDelete = async () => {
        const response = await deleteMessage({ message_id: message.message_id }, localStorage.getItem('access_token'));
        console.log(response);
        setbhaiConversation((prevConversation) => prevConversation.filter((eachMessage) => eachMessage.message_id !== message.message_id));
    };

    // Bhai buttons

    const handleLike = async () => {
        let status = message.like_status;
        if (status !== 'liked') {
            status = 'liked';
        }
        else if (status === 'liked') {
            status = 'neutral';
        }
        await likeMessage({ message_id: message.message_id, like_status: status }, localStorage.getItem('access_token'));
        message.like_status = status;
        setLikeStatus(status)
        setIsDisliked(false);
        if (status === 'liked') {
            setIsLiked(true);
        }
        else {
            setIsLiked(false);
        }
        console.log('Like status: ' + likeStatus);
    };

    const handleDislike = async () => {
        let status = message.like_status;
        if (status !== 'disliked') {
            status = 'disliked';
        }
        else if (status === 'disliked') {
            status = 'neutral';
        }
        await likeMessage({ message_id: message.message_id, like_status: status }, localStorage.getItem('access_token'));
        message.like_status = status;
        setLikeStatus(status)
        setIsLiked(false);
        if (status === 'disliked') {
            setIsDisliked(true);
        }
        else {
            setIsDisliked(false);
        }
        console.log('Like status: ' + likeStatus);
    };

    const handleTogglePlay = (text) => {
        const speech = new SpeechSynthesisUtterance(text);
        setIsPlaying((prev) => !prev);
        if (!isPlaying) {
            window.speechSynthesis.speak(speech);
        }
        else {
            window.speechSynthesis.cancel();
        }
    };

    return (
        <div className='bhai-message-container'>
            {message.origin === 'user' ? (
                <div className="bhai-message-title">
                    <img src={profileImage} alt="Profile Pic" className="profile-pic-user" /> You
                </div>
            ) : (
                <div className="bhai-message-title">
                    <img src={require('../../images/logo.png')} alt="Profile Pic" className="profile-pic-bhai" /> Bhai
                </div>
            )}
            {isEditing ?
                (
                    <input
                        type="text"
                        ref={textInputRef}
                        value={editableContent}
                        className='edit-input'
                        onChange={(e) => setEditableContent(e.target.value)}
                    />
                ) :
                (
                    <div className="bhai-message-content">
                        {message.content}
                    </div>
                )
            }
            {/* For Handling File Conversation */}
            {(message.modal === 'image' || message.modal === 'document' || message.modal === 'audio') && (
                <div className="bhai-message-modal">
                    <span className='bhai-modal-icon'>
                        {message.modal === 'image' && <FaImage />}
                        {message.modal === 'document' && <FaFilePdf />}
                        {message.modal === 'audio' && <MdAudiotrack />}
                    </span>
                    {/* <button className="bhai-download-button" onClick={()=>handleDownload(message.message_id,message.location,message.modal)}>
                         <IoCloudDownloadOutline />
                    </button> */}
                </div>
            )}
            <div className="bhai-message-footer">
                <div className="bhai-buttons">

                    {/* For User */}
                    {message.origin === 'user' && (
                        <>
                            <button className="bhai-delete"
                                onClick={handleDelete}
                            >
                                <MdDelete />
                            </button>
                            {/* Enable Edit Button only for active messages */}
                            {message.active && (
                                <button className="bhai-edit"
                                    onClick={handleEdit}
                                >
                                    <MdEdit />

                                </button>
                            )}
                            {/* Switch button? */}
                        </>
                    )}

                    {/* For Bhai */}
                    {message.origin === 'assistant' && (
                        <>
                            <button className="bhai-delete"
                                onClick={handleDelete}
                            >
                                <MdDelete />
                            </button>
                            <button className="bhai-like"
                                onClick={() => handleLike(message.message_id)}
                            >
                                {isLiked ? (
                                    <FaThumbsUp />
                                ) :
                                    (
                                        <FaRegThumbsUp />
                                    )}
                            </button>
                            <button className="bhai-dislike"
                                onClick={() => handleDislike(message.message_id)}
                            >
                                {isDisliked ? (
                                    <FaThumbsDown />
                                ) :
                                    (
                                        <FaRegThumbsDown />
                                    )}
                            </button>
                            <button className="bhai-play"
                                onClick={() => handleTogglePlay(message.content)}
                            >
                                {isPlaying ? (
                                    <FaRegCirclePause />
                                ) : (
                                    <FaRegCirclePlay />
                                )}
                            </button>

                            {isLastMessage && (
                                <button className="bhai-regenerate"
                                    onClick={() => handleRegenerate()}
                                >
                                    <FaSyncAlt />
                                </button>
                            )}
                        </>
                    )}
                </div>

                <div className="bhai-filters">
                    <Filter
                        message={message}
                        setbhaiConversation={setbhaiConversation}
                    />
                </div>
            </div>
        </div>
    )
}

export default BhaiMessageRenderer;